import { TableCell, Typography } from '@mui/material';
import {FC, ReactNode} from 'react'

interface DashboardTableCellProps {
  content: ReactNode
}

const DashboardTableCell: FC<DashboardTableCellProps> = ({
  content
}) => {
    return (
      <TableCell>
      <Typography
        variant="body1"
        color="text.primary"
        gutterBottom
        noWrap
      >
        {content}
      </Typography>
    </TableCell>
    )
}

export default DashboardTableCell