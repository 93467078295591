import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthService } from "src/api/services/AuthService";
import { User, UserIsAdmin, UserIsBankPage } from "src/models/user";
import { useLocalStorage } from "../hooks/useLocalStorage";

interface AuthContextInterface {
    user: User
    login: (data:any) => Promise<void>
    logout: () => void
}

export const AuthContext = createContext<AuthContextInterface | undefined>(undefined);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null)
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data:any):Promise<void> => {
    try{
      const response = await AuthService.login({
          email: data.email,
          password: data.password
      })

      setUser(response);
      if(UserIsAdmin(response)){
        navigate("/dashboard");
      } else if(UserIsBankPage(response)){
        navigate("/pages");
      } else {
        navigate("/customers");
      }
    } catch(e){
      throw e
    }
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};