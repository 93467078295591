
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Alert, Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { ProductsService } from 'src/api/services/ProductsService';
import { DeclineAttempt } from 'src/models/declineAttempt';
import NewDeclineAttemptIcon from '@mui/icons-material/AddCircle';
import { Product } from 'src/models/product';
import { ApiException } from 'src/models/apiError';
import { DeclineCode } from 'src/models/declineCode';
import { RemoveCircle } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/AddCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface ProductDialogProps {
    isOpen: boolean
    existingProduct?: Product
    serverIP?:string
    onClose: (shouldRefresh: boolean) => void
}

const ProductDialog: FC<ProductDialogProps> = ({ isOpen = false, existingProduct, serverIP, onClose }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>('')

    const [name, setName] = useState<string>('')
    const [slug, setSlug] = useState<string>('')
    const [price, setPrice] = useState<string>('')
    const [shippingPrice, setShippingPrice] = useState<string>('')
    const [content, setContent] = useState<string>('')
    const [isSubscription, setIsSubscription] = useState<boolean>(false)
    const [images, setImages] = useState<any[]>([])

    const handleClose = () => {
        onClose(false)
    };

    useEffect(() => {
        if (existingProduct) {
            setName(existingProduct.name)
            setSlug(existingProduct.slug)
            setPrice(existingProduct.price)
            setShippingPrice(existingProduct.shipping_price)
            setContent(existingProduct.content)
            setImages(existingProduct.images)
            setIsSubscription(existingProduct.is_subscription == 1)
        } else {
            setName('')
            setSlug('')
            setPrice('')
            setShippingPrice('')
            setContent('')
            setImages([])
            setIsSubscription(false)
        }
        setError('')
    }, [existingProduct])

    const handleOnDelete = async () => {
        setIsLoading(true)
        try{
            await ProductsService.delete({
                id: existingProduct.id,
            })
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleOnSave = async () => {
        setIsLoading(true)
        setError('')
        try{
            if (existingProduct) {
                //Edit
                await ProductsService.edit({
                    id: existingProduct.id,
                    name,
                    slug,
                    price,
                    shipping_price:shippingPrice,
                    is_subscription:isSubscription ? 1 : 0,
                    content,
                }, images)
            } else {
                //Create
                await ProductsService.create({
                    name,
                    slug,
                    price,
                    shipping_price:shippingPrice,
                    is_subscription:isSubscription ? 1 : 0,
                    content,
               }, images)
            }
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>, index:number) => {
        const { target } = event
        console.log(target)
        //const template = templates.find(t => `${t.id}` == value)
        const file = target.files[0];
        const fileReader = new FileReader();
        const name = target.accept.includes('image') ? 'images' : 'videos';

        fileReader.onload = (e) => {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement("canvas");
                const MAX_WIDTH = 800;
                const MAX_HEIGHT = 800;
                let width = img.width;
                let height = img.height;
          
                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
          
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
          
                canvas.toBlob(async (blob) => {
                  const fileType = file.type.split("/")[1];
                  let newFile:File = null;
          
                  if (fileType === "jpeg" || fileType === "jpg") {
                    newFile = new File([blob], file.name, { type: "image/jpeg" });
                  } else if (fileType === "png") {
                    newFile = new File([blob], file.name, { type: "image/png" });
                  } else if (fileType === "gif") {
                    newFile = new File([blob], file.name, { type: "image/gif" });
                  } else {
                    return
                  }
          
                    let newImages = [...images]
                    //it's a child inside of an array
                    newImages[index][`file`] = await fileToBase64(newFile)
                    setImages(newImages)
                }, file.type, 0.8);
            };
            if(typeof e.target.result === 'string'){
                img.src = e.target.result;
            }
        }
        fileReader.readAsDataURL(file);
    }

    async function fileToBase64(file:File):Promise<string|ArrayBuffer> {
        const reader = new FileReader()
        return new Promise(resolve => {
          reader.onload = ev => {
            resolve(ev.target.result)
          }
          reader.readAsDataURL(file)
        })
    }
    
    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{existingProduct ? 'Edit Product' : 'Create Product'}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1} columns={{ xs: 1, mb:2 }} mb={1}>
                    <Grid item xs={1}>
                        <TextField
                            autoFocus
                            label="Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Slug"
                            value={slug}
                            onChange={(e) => {
                                setSlug(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Price"
                            value={price}
                            onChange={(e) => {
                                setPrice(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Shipping Price"
                            value={shippingPrice}
                            onChange={(e) => {
                                setShippingPrice(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox value={isSubscription} checked={isSubscription} onChange={(event, checked) => {
                                setIsSubscription(checked)
                            }} />} label="Is Subscription?" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={1} mb={2}>
                        <ReactQuill
                            value={content}
                            placeholder={"Description"}
                            onChange={(e) => {
                                setContent(e)
                            }}
                            modules={{
                                toolbar: [
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    ['bold', 'italic', 'underline', 'strike'], 
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'align': [] }],
                                    ['clean'] 
                                ]
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} mb={2}>
                        <Grid item xs={2}>
                        <Typography mb={1} >
                            Images
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                                { images.map((image, i) => {
                                    const apiBaseURL = new URL(window.config.API_BASE_URL);
                                    // This checks if the pathname is exactly '/api' or '/api/' and then replaces it with '/images'
                                    if (apiBaseURL.pathname === '/api' || apiBaseURL.pathname === '/api/') {
                                      apiBaseURL.pathname = '/images/';
                                    }
                                    const imageBasePath = apiBaseURL.href

return <Grid container spacing={1} columns={{ xs: 2 }} key={`children_${i}`}>
                                        <Grid item xs={1} key={`image_${i}`}>
                                <Button component="label" endIcon={<UploadFileIcon />}>
                                {`Image ${i+1}`}
                                <input hidden accept="image/*" multiple type="file" onChange={(event)=> handleImageChange(event, i)}/>
                            </Button>
                            {image[`image`] && <Box component="img" src={`${imageBasePath}${image[`image`]}`} sx={{
                                width: 'auto',
                                maxHeight: '50px'
                            }} /> }
                            { image[`file`] && <Box component="img" src={image[`file`]} sx={{
                                width: 'auto',
                                maxHeight: '50px'
                            }} /> }
                        </Grid>

                            <Button component="label" endIcon={<RemoveCircle />} onClick={() => {
                                let newImages = [...images]
                                newImages.splice(i, 1)
                                setImages(newImages)
                            }}>
                                Remove
                            </Button>
                            </Grid>
                        }) }
                            <Button component="label" endIcon={<AddIcon />} onClick={() => {
                                let newImages = [...images]
                                newImages.push({})
                                setImages(newImages)
                            }}>
                                Add Image
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                { error && <Alert severity="error">
                    {error}
                </Alert> }
            </DialogContent>
            <Divider />
            <DialogActions>
                { existingProduct && <LoadingButton
                    color='error'
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<DeleteIcon />}
                    onClick={handleOnDelete}
                >Delete</LoadingButton> }
                <Box sx={{flex: '1 0 0'}} />
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    onClick={handleOnSave}
                >{existingProduct ? 'Save' : 'Create Product'}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default ProductDialog;

