import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  CardHeader,
  Link as MuiLink,
  Backdrop,
  styled,
  CircularProgress,
  Button,
  IconButton,
} from '@mui/material';

import { Order } from 'src/models/order';
import { useNavigate } from 'react-router-dom';
import { OrdersService } from 'src/api/services/OrdersService';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { format } from 'date-fns';
import NewUserIcon from '@mui/icons-material/AddCircle';
import { UsersService } from 'src/api/services/UsersService';
import { User } from 'src/models/user';
import UserDialog from './UserDialog';

interface UsersTableProps {
  className?: string;
}

const LimitedBackdrop = styled(Backdrop)(
  () => `
    position: absolute;
    z-index: 1;
`
);

const UsersTable: FC<UsersTableProps> = () => {
  const [users, setUsers] = useState<User[]>([])
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
  const [limit, setLimit] = useState<number>(10);
  const [isRefreshing, setIsRefreshing] = useState(true);

  const refreshData = async () => {
    setIsRefreshing(true)
    const response = await UsersService.users()
    setUsers(response)
    setIsRefreshing(false)
  }

  useEffect(()=>{
    refreshData()
  },[])

  const onUserDialogClose = async (shouldRefresh:boolean) => {
    setIsUserDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  return <>
    <Card sx={{
      position:'relative'
    }}>
        <CardHeader
          action={
            <IconButton onClick={() => setIsUserDialogOpen(true)}>
              <NewUserIcon />
            </IconButton>
          }
          title="Users"
        />
      <Divider />
      <LimitedBackdrop open={isRefreshing}><CircularProgress size={'5rem'} /></LimitedBackdrop>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Created</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((item) => {
              return (
                <TableRow
                  key={item.email}
                  >
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {format(new Date(item.created_at), 'M/d/yyyy h:mm a')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.roles.map((e) => e.name).join(',')}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
    <UserDialog isOpen={isUserDialogOpen} onClose={onUserDialogClose} />
  </>
};

export default UsersTable;
