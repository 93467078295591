
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Alert, Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppsService } from 'src/api/services/AppsService';
import { DeclineAttempt } from 'src/models/declineAttempt';
import NewDeclineAttemptIcon from '@mui/icons-material/AddCircle';
import { App } from 'src/models/app';
import { ApiException } from 'src/models/apiError';
import { DeclineCode } from 'src/models/declineCode';

interface AppDialogProps {
    isOpen: boolean
    existingApp?: App
    onClose: (shouldRefresh: boolean) => void
}

const AppDialog: FC<AppDialogProps> = ({ isOpen = false, existingApp, onClose }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>('')

    const [name, setName] = useState<string>('')
    const [endpoint, setEndpoint] = useState<string>('')
    const [config, setConfig] = useState<any>('')
    const [requestDelayDays, setRequestDelayDays] = useState<string>('')
    const [requestDelay, setRequestDelay] = useState<string>('')
    const [requestDelayEnabled, setRequestDelayEnabled] = useState(false)

    const handleClose = () => {
        onClose(false)
    };

    useEffect(() => {
        if (existingApp) {
            setName(existingApp.name)
            setEndpoint(existingApp.endpoint)
            setConfig(existingApp.config)
            if(existingApp.request_delay){
                var request_delay = parseInt(existingApp.request_delay)
                if(request_delay < 1440){
                    setRequestDelayDays('0')
                } else {
                    setRequestDelayDays((parseInt(existingApp.request_delay) / 1440).toString())
                }
            } else {
                setRequestDelayDays('')
            }
            setRequestDelay(existingApp.request_delay)
            setRequestDelayEnabled(existingApp.request_delay && existingApp.request_delay !== '')
        } else {
            setName('')
            setEndpoint('')
            setConfig({})
            setRequestDelayDays('')
            setRequestDelay('')
        }
        setError('')
    }, [existingApp])

    const handleOnDelete = async () => {
        setIsLoading(true)
        try{
            await AppsService.delete({
                id: existingApp.id,
            })
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleOnSave = async () => {
        setIsLoading(true)
        setError('')
        try{
            var request_delay = ''
            if(requestDelayDays !== null && requestDelayDays !== undefined && requestDelayDays !== ''){
                //request_delay is in minutes. So convert days to minutes if needed
                if(requestDelayDays === '0'){
                    request_delay = requestDelay
                } else {
                    request_delay = (parseInt(requestDelayDays) * 1440).toString()
                }
            }
            if (existingApp) {
                //Edit
                await AppsService.edit({
                    id: existingApp.id,
                    name,
                    endpoint,
                    config,
                    request_delay,
                })
            } else {
                //Create
                await AppsService.create({
                    name,
                    endpoint,
                    config,
                    request_delay,
                })
            }
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{existingApp ? 'Edit App' : 'Create App'}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1} columns={{ xs: 2 }} mb={1}>
                    <Grid item xs={2}>
                        <TextField
                            label="Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Endpoint"
                            value={endpoint}
                            onChange={(e) => {
                                setEndpoint(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox value={requestDelayEnabled} checked={requestDelayEnabled} onChange={(event, checked) => {
                                setRequestDelayEnabled(checked)
                                if (!checked) {
                                    setRequestDelay('')
                                }
                            }} />} label="Enable Transmission Delay" />
                        </FormGroup>
                    </Grid>
                    {requestDelayEnabled && <Grid container item spacing={1} columns={{ xs: 1, sm: 2 }} mb={1}>
                     <Grid item xs={1}>
                        <TextField
                            label="Transmission delay"
                            value={requestDelayDays}
                            type='number'
                            defaultValue='1'
                            placeholder='1'
                            onChange={(e) => {
                                setRequestDelayDays(e.target.value)
                                if(e.target.value === '0'){
                                    setRequestDelay('')
                                } else {
                                    setRequestDelay(undefined)
                                }
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">days</InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    {requestDelayDays != undefined && parseInt(requestDelayDays) == 0 && <Grid item xs={1}>
                        <TextField
                            label="Transmission delay"
                            value={requestDelay}
                            type='number'
                            placeholder='15'
                            onChange={(e) => {
                                setRequestDelay(e.target.value)
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">minutes</InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid> }
                    </Grid>}
                    {config && Object.keys(config).map((key) => {
                        const value = config[key]
                    return <Grid item xs={1} key={key}>
                    <TextField
                    label={key}
                    value={value}
                    onChange={(e) => {
                        let newConfig = {...config}
                        newConfig[key] = e.target.value
                        setConfig(newConfig)
                    }}
                    fullWidth
                /></Grid>
                })}
                </Grid>
                { error && <Alert severity="error">
                    {error}
                </Alert> }
            </DialogContent>
            <Divider />
            <DialogActions>
                { existingApp && <LoadingButton
                    color='error'
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<DeleteIcon />}
                    onClick={handleOnDelete}
                >Delete</LoadingButton> }
                <Box sx={{flex: '1 0 0'}} />
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    onClick={handleOnSave}
                >{existingApp ? 'Save' : 'Create App'}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default AppDialog;

