import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { PaginatedState } from 'src/models/paginatedState';
import PixelsTable from './PixelsTable';
import { Offer } from 'src/models/offer';
import { PixelsService } from 'src/api/services/PixelsService';
import PixelDialog from './PixelDialog';
import { PaginatedResponse } from 'src/models/paginatedResponse';

function ApplicationsOffers() {
  const [paginatedResponse, setPaginatedResponse] = useState<PaginatedResponse<Offer>>()
  const [paginatedState, setPaginatedState] = useState<PaginatedState>({per_page: 10, page: 0})
  const [searchTerms, setSearchTerms] = useState<Partial<Offer>>({
    name: '',
  })

  const [isSearching, setIsSearching] = useState(false)

  const onSearch = async (terms:Partial<Offer>) => {
    console.log(terms)
    const newTerms = {...terms}
    setSearchTerms(newTerms)
  }

  const onClear = async (terms:Partial<Offer>) => {
    setSearchTerms(terms)
  }

  const refreshData = async () => {
    setIsSearching(true)
    try{
      const response = await PixelsService.search({
        ...searchTerms,
      }, paginatedState)
      setPaginatedResponse(response)
    } catch(e){}
    setIsSearching(false)
  }

  useEffect(() => {
    refreshData()
  },[searchTerms, paginatedState])

  const onPageChange = (newPage: number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.page = newPage
    setPaginatedState(newPaginatedState)
  };

  const onRowsPerPageChange = (newRowsPerPage:number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.per_page = newRowsPerPage
    setPaginatedState(newPaginatedState)
  };

  const [isPixelDialogOpen, setIsPixelDialogOpen] = useState(false)
  const [existingPixel, setExistingPixel] = useState<Offer>()

  const onEditPixel = (pixel:Offer) => {
    setExistingPixel(pixel)
    setIsPixelDialogOpen(true)
  }

  const onPixelDialogClose = (shouldRefresh:boolean) => {
    setIsPixelDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  return (
    <>
      <Helmet>
        <title>Offers</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
            {/*<PageHeader 
              searchTerms={searchTerms} 
              isSearching={isSearching} 
              onSearch={onSearch} 
              onClear={onClear} 
              onNewOffer={onNewOffer} 
            />*/}
          </Grid>
          <Grid item xs={12}>
            <PixelsTable 
              paginatedResponse={paginatedResponse} 
              paginatedState={paginatedState} 
              isRefreshing={isSearching} 
              onPageChange={onPageChange} 
              onRowsPerPageChange={onRowsPerPageChange}
              onEditPixel={onEditPixel}
            />
          </Grid>
        </Grid>
      </Container>
      <PixelDialog 
        isOpen={isPixelDialogOpen} 
        onClose={onPixelDialogClose} 
        existingPixel={existingPixel} 
      />
    </>
  );
}

export default ApplicationsOffers;
