import { Product } from "src/models/product";
import Api from "..";
import { PaginatedResponse } from "src/models/paginatedResponse";
import { PaginatedState } from "src/models/paginatedState";

export class ProductsService {
  static async getAll(): Promise<Product[]> {
    try {
      const { data } = await Api.client.get<Product[]>(`products`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async search({
    name = '',
  }, paginatedState: PaginatedState = { per_page: 10, page: 0 }): Promise<PaginatedResponse<Product>> {
    var params = {
      name,
    }

    const queryString = Object.entries(paginatedState)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    const baseUrl = 'products/search';
    const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    try {
      const { data } = await Api.client.post<PaginatedResponse<Product>>(urlWithQuery, params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async create(product: Partial<Product>, images: any[]): Promise<Product> {
    try {
      const { data } = await Api.client.post('products', {
        name: product.name,
        slug: product.slug,
        price: product.price,
        shipping_price: product.shipping_price,
        content: product.content,
        is_subscription: product.is_subscription,
        images
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async edit(product: Partial<Product>, images: any[]): Promise<Product> {
    try {
      const { data } = await Api.client.post('products/edit', {
        id: product.id,
        name: product.name,
        slug: product.slug,
        price: product.price,
        shipping_price: product.shipping_price,
        content: product.content,
        is_subscription: product.is_subscription,
        images
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async delete(product: Partial<Product>): Promise<void> {
    try {
      await Api.client.delete(`products/${product.id}`);
    } catch (e) {
      throw e;
    }
  }

  static async refresh(product: Partial<Product>): Promise<Product> {
    try {
      const { data } = await Api.client.get(`products/${product.id}/refresh`);
      return data.product
    } catch (e) {
      throw e;
    }
  }

  static async deactivate(productID: number): Promise<Product> {
    try {
      const { data } = await Api.client.post(`products/${productID}/deactivate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async activate(productID: number): Promise<Product> {
    try {
      const { data } = await Api.client.post(`products/${productID}/activate`);
      return data;
    } catch (e) {
      throw e;
    }
  }
}