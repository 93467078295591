import { Page } from "src/models/page";
import Api from "..";
import { PageProduct } from "src/models/pageProduct";
import { PaginatedResponse } from "src/models/paginatedResponse";
import { PaginatedState } from "src/models/paginatedState";

export class PagesService {
  static async getAll():Promise<Page[]> {
    try {
      const { data } = await Api.client.get<Page[]>(`pages`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async search({
    name = '',
    corporation_name = '',
  }, paginatedState: PaginatedState = { per_page: 10, page: 0 }):Promise<PaginatedResponse<Page>> {
    var params = {
      name,
      corporation_name,
    }

    const queryString = Object.entries(paginatedState)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

    const baseUrl = 'pages/search';
    const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    try {
      const { data } = await Api.client.post<PaginatedResponse<Page>>(urlWithQuery, params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async create(page:Partial<Page>, pageProducts:PageProduct[], templateContent:any):Promise<Page> {
    try {
      const { data } = await Api.client.post('pages', {
        ...page,
        page_products: pageProducts,
        template_content: templateContent,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async edit(page:Partial<Page>, pageProducts:PageProduct[], templateContent:any):Promise<Page> {
    try {
      const { data } = await Api.client.post('pages/edit', {
        ...page,
        page_products: pageProducts,
        template_content: templateContent,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async delete(page:Partial<Page>):Promise<void> {
    try {
      await Api.client.delete(`pages/${page.id}`);
    } catch (e) {
      throw e;
    }
  }

  static async refresh(page:Partial<Page>):Promise<Page> {
    try {
      const { data } = await Api.client.get(`pages/${page.id}/refresh`);
      return data.page
    } catch (e) {
      throw e;
    }
  }

  static async deactivate(pageID:number):Promise<Page> {
    try {
      const { data } = await Api.client.post(`pages/${pageID}/deactivate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async activate(pageID:number):Promise<Page> {
    try {
      const { data } = await Api.client.post(`pages/${pageID}/activate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

}