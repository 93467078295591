
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Alert, Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { TemplatesService } from 'src/api/services/TemplatesService';
import { Template, TemplatePath } from 'src/models/template';
import { ApiException } from 'src/models/apiError';
import UploadFileIcon from '@mui/icons-material/UploadFile';

interface TemplateDialogProps {
    isOpen: boolean
    existingTemplate?: Template
    onClose: (shouldRefresh: boolean) => void
}

const TemplateDialog: FC<TemplateDialogProps> = ({ isOpen = false, existingTemplate, onClose }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>('')

    const [name, setName] = useState<string>('')
    const [image, setImage] = useState<string>('')
    const [imageFile, setImageFile] = useState<any>()

    const handleClose = () => {
        onClose(false)
    };

    useEffect(() => {
        if (existingTemplate) {
            setName(existingTemplate.name)
            setImage(existingTemplate.image)
        } else {
            setName('')
            setImage('')
        }
        setError('')
    }, [existingTemplate])

    const handleOnDelete = async () => {
        setIsLoading(true)
        try{
            await TemplatesService.delete({
                id: existingTemplate.id,
            })
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleOnSave = async () => {
        setIsLoading(true)
        setError('')
        try{
            if (existingTemplate) {
                //Edit
                await TemplatesService.edit({
                    id: existingTemplate.id,
                    name,
                }, imageFile)
            } else {
                //Create
                await TemplatesService.create({
                    name,
                }, imageFile)
            }
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { target } = event
        console.log(target)
        //const template = templates.find(t => `${t.id}` == value)
        const file = target.files[0];
        const fileReader = new FileReader();
        const name = target.accept.includes('image') ? 'images' : 'videos';

        fileReader.onload = (e) => {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement("canvas");
                const MAX_WIDTH = 800;
                const MAX_HEIGHT = 800;
                let width = img.width;
                let height = img.height;
          
                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
          
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
          
                canvas.toBlob(async (blob) => {
                  const fileType = file.type.split("/")[1];
                  let newFile:File = null;
          
                  if (fileType === "jpeg" || fileType === "jpg") {
                    newFile = new File([blob], file.name, { type: "image/jpeg" });
                  } else if (fileType === "png") {
                    newFile = new File([blob], file.name, { type: "image/png" });
                  } else if (fileType === "gif") {
                    newFile = new File([blob], file.name, { type: "image/gif" });
                  } else {
                    return
                  }
          
                  let file64 = await fileToBase64(newFile)
                  setImageFile(file64)
                }, file.type, 0.8);
            };
            if(typeof e.target.result === 'string'){
                img.src = e.target.result;
            }
        }
        fileReader.readAsDataURL(file);
    }

    async function fileToBase64(file:File):Promise<string|ArrayBuffer> {
        const reader = new FileReader()
        return new Promise(resolve => {
          reader.onload = ev => {
            resolve(ev.target.result)
          }
          reader.readAsDataURL(file)
        })
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{existingTemplate ? 'Edit Template' : 'Create Template'}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1} columns={{ xs: 1 }} mb={1}>
                    <Grid item xs={1}>
                        <TextField
                            autoFocus
                            label="Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                    <Typography mb={1} >
                        Image
                    </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Button component="label" endIcon={<UploadFileIcon />}>
                            Upload
                            <input hidden accept="image/*" multiple type="file" onChange={(event)=> handleImageChange(event)}/>
                        </Button>
                        { image && imageFile === undefined && <Box component="img" src={`${TemplatePath(existingTemplate)}/images/${image}`} sx={{
                            width: 'auto',
                            maxHeight: '50px'
                        }} /> }
                        { imageFile && <Box component="img" src={imageFile} sx={{
                            width: 'auto',
                            maxHeight: '50px'
                        }} /> }
                    </Grid>
                </Grid>
                { error && <Alert severity="error">
                    {error}
                </Alert> }
            </DialogContent>
            <Divider />
            <DialogActions>
                { existingTemplate && <LoadingButton
                    color='error'
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<DeleteIcon />}
                    onClick={handleOnDelete}
                >Delete</LoadingButton> }
                <Box sx={{flex: '1 0 0'}} />
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    onClick={handleOnSave}
                >{existingTemplate ? 'Save' : 'Create Template'}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default TemplateDialog;

