import { Customer } from "./customer"
import { Flow } from "./flow"
import { TrackingInfo } from "./trackingInfo"

export interface Transaction {
  id:string
  customer_id:number
  flow_id:number
  credit_card_id:number
  tracking_info_id:number
  parent_id:number
  offer_id:number
  internal_campaign_id:number
  decline_attempt_id:number
  cycle:number
  attempt:number
  charge_at:string
  charge_cancelled_at:string
  charge_success_at:string
  charge_declined_at:string
  charge_processing_at:string
  cancellation_notes:string
  external_campaign_id:number
  external_connection_id:number
  external_offer_id:string
  external_merchant_id:number
  external_order_id:number
  is_initial:number
  created_at:string
  updated_at:string
  status:string
  customer?:Customer
  flow?:Flow
  tracking_info?:TrackingInfo
}

export const TransactionWasBilled = (transaction:Transaction) => {
  return transaction.charge_success_at != undefined || transaction.charge_declined_at != undefined;
}