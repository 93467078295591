import { User } from "src/models/user";
import Api from "..";
import { Company } from "src/models/company";

export class UsersService {
  static async users():Promise<User[]> {
    try {
      const { data } = await Api.client.get<User[]>(`users`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async create(user:Partial<User>):Promise<User> {
    const params = {
      name: user.name, 
      email: user.email,
      role: user.role,
    }
    try {
      const { data } = await Api.client.post('register', params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async getCompany():Promise<Company> {
    try {
      const { data } = await Api.client.get<Company>('user/company');
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async editCompanySettings(settings:any):Promise<Company> {
    try {
      const { data } = await Api.client.post('user/company/settings/edit', {
        settings: settings,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

}