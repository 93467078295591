
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { TermsService } from 'src/api/services/TermsService';
import { Term } from 'src/models/term';
import { ApiException } from 'src/models/apiError';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface TermDialogProps {
    isOpen: boolean
    existingTerm?: Term
    serverIP?:string
    onClose: (shouldRefresh: boolean) => void
}

const TermDialog: FC<TermDialogProps> = ({ isOpen = false, existingTerm, serverIP, onClose }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>('')

    const [name, setName] = useState<string>('')
    const [content, setContent] = useState<string>('')

    const handleClose = () => {
        onClose(false)
    };

    useEffect(() => {
        if (existingTerm) {
            setName(existingTerm.name)
            setContent(existingTerm.content)
        } else {
            setName('')
            setContent('')
        }
        setError('')
    }, [existingTerm])

    const handleOnDelete = async () => {
        setIsLoading(true)
        try{
            await TermsService.delete({
                id: existingTerm.id,
            })
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleOnSave = async () => {
        setIsLoading(true)
        setError('')
        try{
            if (existingTerm) {
                //Edit
                await TermsService.edit({
                    id: existingTerm.id,
                    name,
                    content,
                })
            } else {
                //Create
                await TermsService.create({
                    name,
                    content,
                })
            }
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{existingTerm ? 'Edit Term' : 'Create Term'}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1} columns={{ xs: 1 }} mb={1}>
                    <Grid item xs={1}>
                        <TextField
                            autoFocus
                            label="Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <ReactQuill
                            value={content}
                            placeholder={"Content"}
                            onChange={(e) => {
                                setContent(e)
                            }}
                            modules={{
                                toolbar: [
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    ['bold', 'italic', 'underline', 'strike'], 
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'align': [] }],
                                    ['clean'] 
                                ]
                            }}
                        />
                    </Grid>
                </Grid>
                { error && <Alert severity="error">
                    {error}
                </Alert> }
            </DialogContent>
            <Divider />
            <DialogActions>
                { existingTerm && <LoadingButton
                    color='error'
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<DeleteIcon />}
                    onClick={handleOnDelete}
                >Delete</LoadingButton> }
                <Box sx={{flex: '1 0 0'}} />
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    onClick={handleOnSave}
                >{existingTerm ? 'Save' : 'Create Term'}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default TermDialog;

