
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Box, DialogContent, DialogContentText, Divider, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { InternalCampaign } from 'src/models/internalCampaign';
import { CampaignsService } from 'src/api/services/CampaignsService';
import DuplicateIcon from '@mui/icons-material/CopyAll';

interface CampaignDuplicationDialogProps {
    isOpen:boolean
    existingCampaign?:InternalCampaign
    onClose:(shouldRefresh:boolean) => void
}

const CampaignDuplicationDialog: FC<CampaignDuplicationDialogProps> = ({isOpen = false, existingCampaign, onClose}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState('')
    const [username, setUsername] = useState('')
  
    const handleClose = () => {
        onClose(false)
        setName('')
        setUsername('')
    }; 

    const handleOnDuplicate = async () => {
        if(username == '' || name == '') {
            return
        }
        setIsLoading(true)
        try{
            await CampaignsService.duplicate(existingCampaign.id, name, username)
            onClose(true)
            setName('')
            setUsername('')
        }catch(e){}
        setIsLoading(false)
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Duplicate Product: {existingCampaign?.name}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText marginBottom={'1rem'}>
          Please enter the required values for the new product
          </DialogContentText>
          <TextField
            sx={{mb:1}}
            autoFocus
            label="New Product Name"
            value={name}
            onChange={(e) => {
              setName(e.currentTarget.value)
            }}
            fullWidth
          />
        <TextField
            label="New Username"
            value={username}
            onChange={(e) => {
              setUsername(e.currentTarget.value)
            }}
            fullWidth
        />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Box sx={{flex: '1 0 0'}}></Box>
          <LoadingButton
            loading={isLoading}
            loadingPosition='start'
            startIcon={<DuplicateIcon />}
            color='success'
            onClick={handleOnDuplicate}
          >Duplicate</LoadingButton>
        </DialogActions>
      </Dialog>
    )
}

export default CampaignDuplicationDialog;

