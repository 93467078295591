import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  CardHeader,
  Link as MuiLink,
  Backdrop,
  styled,
  CircularProgress,
  Button,
  IconButton,
} from '@mui/material';

import { Order } from 'src/models/order';
import { useNavigate } from 'react-router-dom';
import { OrdersService } from 'src/api/services/OrdersService';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { format } from 'date-fns';
import RefreshIcon from '@mui/icons-material/Refresh';
import { PaginatedState } from 'src/models/paginatedState';
import { AppRequest } from 'src/models/appRequest';

interface AppRequestsTableProps {
  className?: string
  paginatedResponse?: PaginatedResponse<AppRequest>
  paginatedState: PaginatedState
  isRefreshing:boolean
  onPageChange:(page:number) => void
  onRowsPerPageChange:(rowsPerPage:number) => void
}

const LimitedBackdrop = styled(Backdrop)(
  () => `
    position: absolute;
    z-index: 1;
`
);

const AppRequestsTable: FC<AppRequestsTableProps> = ({paginatedResponse, paginatedState, isRefreshing = false, onPageChange, onRowsPerPageChange}) => {
  const navigate = useNavigate()

  const handlePageChange = async (event: any, newPage: number) => {
    onPageChange(newPage)
  };

  const handleLimitChange = async (event: ChangeEvent<HTMLInputElement>) => {
    onRowsPerPageChange(parseInt(event.target.value))
  };

  return (
    <Card sx={{
      position:'relative'
    }}>
      <LimitedBackdrop open={isRefreshing}><CircularProgress size={'5rem'} /></LimitedBackdrop>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Attempt</TableCell>
              <TableCell>Request At</TableCell>
              <TableCell>Failed At</TableCell>
              <TableCell>response</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedResponse?.data.map((appRequest) => {
              return (
                <TableRow
                  key={appRequest.id}
                  >
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {appRequest.id}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {appRequest.attempt}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {format(new Date(appRequest.request_at), 'M/d/yyyy h:mm a')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {format(new Date(appRequest.request_failed_at), 'M/d/yyyy h:mm a')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                    >
                      {appRequest.response}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={paginatedResponse?.total ?? 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={paginatedState.page}
          rowsPerPage={paginatedState.per_page}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default AppRequestsTable;
