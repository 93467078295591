import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Grid, TextField, TextFieldProps } from '@mui/material';

// ? Type of Props the FormInput will receive
type FormInputProps = {
    name: string;
  } & TextFieldProps;
  
  const GridFormInput: FC<FormInputProps> = ({ name, ...otherProps }) => {
    // ? Utilizing useFormContext to have access to the form Context
    const {
      control,
      formState: { errors },
    } = useFormContext();
  
    return (
      <Grid item xs={1}>
        <Controller
          control={control}
          name={name}
          defaultValue=''
          render={({ field }) => (
            <TextField
              {...field}
              {...otherProps}
              fullWidth
              error={!!errors[name]}
              helperText={
                errors[name] ? (errors[name]?.message as unknown as string) : ''
              }
            />
          )}
        />
      </Grid>
    );
  };
  
  export default GridFormInput;
  