import { Offer } from "src/models/offer";
import Api from "..";
import { PaginatedState } from "src/models/paginatedState";
import { PaginatedResponse } from "src/models/paginatedResponse";

export class PixelsService {
  static async search({
    name = '',
  }, paginatedState: PaginatedState = { per_page: 10, page: 0 }):Promise<PaginatedResponse<Offer>> {
    var params = {
      name,
    }

    const queryString = Object.entries(paginatedState)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

    const baseUrl = 'pixels/search';
    const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    try {
      const { data } = await Api.client.post<PaginatedResponse<Offer>>(urlWithQuery, params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async edit(offer:Partial<Offer>):Promise<Offer> {
    try {
      const { data } = await Api.client.post('pixels/edit', {
        ...offer,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }
}