import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { PaginatedState } from 'src/models/paginatedState';
import { OffersService } from 'src/api/services/OffersService';
import OffersTable from './OffersTable';
import { Offer } from 'src/models/offer';
import OfferDialog from './OfferDialog';
import NewOfferIcon from '@mui/icons-material/AddCircle';
import { LoadingButton } from '@mui/lab';
import OfferActivationDialog from './OfferActivationDialog';
import { Domain } from 'src/models/domain';
import { Template } from 'src/models/template';
import { DomainsService } from 'src/api/services/DomainsService';
import { TemplatesService } from 'src/api/services/TemplatesService';
import { App } from 'src/models/app';
import { AppsService } from 'src/api/services/AppsService';
import { InternalCampaign } from 'src/models/internalCampaign';
import { CampaignsService } from 'src/api/services/CampaignsService';
import { PaginatedResponse } from 'src/models/paginatedResponse';

function ApplicationsOffers() {
  const [paginatedResponse, setPaginatedResponse] = useState<PaginatedResponse<Offer>>()
  const [paginatedState, setPaginatedState] = useState<PaginatedState>({per_page: 10, page: 0})
  const [searchTerms, setSearchTerms] = useState<Partial<Offer>>({
    name: '',
  })

  const [isSearching, setIsSearching] = useState(false)
  const [domains, setDomains] = useState<Domain[]>([])
  const [templates, setTemplates] = useState<Template[]>([])
  const [apps, setApps] = useState<App[]>([])
  const [internalCampaigns, setInternalCampaigns] = useState<InternalCampaign[]>([])

  const onSearch = async (terms:Partial<Offer>) => {
    console.log(terms)
    const newTerms = {...terms}
    setSearchTerms(newTerms)
  }

  const onClear = async (terms:Partial<Offer>) => {
    setSearchTerms(terms)
  }

  const refreshData = async () => {
    setIsSearching(true)
    try{
      const response = await OffersService.search({
        ...searchTerms,
      }, paginatedState)
      setPaginatedResponse(response)
      if(domains.length <= 0){
        const d = await DomainsService.getAll();
        setDomains(d)
      }
      if(templates.length <= 0){
        const t = await TemplatesService.getAll();
        setTemplates(t)
      }
      if(apps.length <= 0){
        const a = await AppsService.getAll();
        setApps(a)
      }
      if(internalCampaigns.length <= 0){
        const c = await CampaignsService.getAll();
        setInternalCampaigns(c)
      }
    } catch(e){}
    setIsSearching(false)
  }

  useEffect(() => {
    refreshData()
  },[searchTerms, paginatedState])

  const onPageChange = (newPage: number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.page = newPage
    setPaginatedState(newPaginatedState)
  };

  const onRowsPerPageChange = (newRowsPerPage:number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.per_page = newRowsPerPage
    setPaginatedState(newPaginatedState)
  };

  const [isOfferDialogOpen, setIsOfferDialogOpen] = useState(false)
  const [existingOffer, setExistingOffer] = useState<Offer>()

  const onNewOffer = () => {
    setExistingOffer(undefined)
    setIsOfferDialogOpen(true)
  }

  const onEditOffer = (offer:Offer) => {
    setExistingOffer(offer)
    setIsOfferDialogOpen(true)
  }

  const onOfferDialogClose = (shouldRefresh:boolean) => {
    setIsOfferDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  const [isOfferActivationDialogOpen, setIsOfferActivationDialogOpen] = useState(false)

  const onActivateOffer = (offer:Offer) => {
    setExistingOffer(offer)
    setIsOfferActivationDialogOpen(true)
  }

  const onDeactivateOffer = (offer:Offer) => {
    setExistingOffer(offer)
    setIsOfferActivationDialogOpen(true)
  }

  const onOfferActivationDialogClose = (shouldRefresh:boolean) => {
    setIsOfferActivationDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  return (
    <>
      <Helmet>
        <title>Offers</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
            {/*<PageHeader 
              searchTerms={searchTerms} 
              isSearching={isSearching} 
              onSearch={onSearch} 
              onClear={onClear} 
              onNewOffer={onNewOffer} 
            />*/}
            <LoadingButton
              loading={isSearching}
              loadingPosition='start'
              startIcon={<NewOfferIcon />}
              variant='contained'
              onClick={() => {
                onNewOffer()
              }}
            >New Offer</LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <OffersTable 
              paginatedResponse={paginatedResponse} 
              paginatedState={paginatedState} 
              isRefreshing={isSearching} 
              onPageChange={onPageChange} 
              onRowsPerPageChange={onRowsPerPageChange}
              onEditOffer={onEditOffer}
              onActivateOffer={onActivateOffer}
              onDeactivateOffer={onDeactivateOffer}
            />
          </Grid>
        </Grid>
      </Container>
      <OfferActivationDialog 
        isOpen={isOfferActivationDialogOpen} 
        onClose={onOfferActivationDialogClose} 
        existingOffer={existingOffer} 
      />
      <OfferDialog 
        isOpen={isOfferDialogOpen} 
        onClose={onOfferDialogClose} 
        existingOffer={existingOffer} 
        domains={domains} 
        templates={templates} 
        internalCampaigns={internalCampaigns} 
        apps={apps} 
      />
    </>
  );
}

export default ApplicationsOffers;
