
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Domain } from 'src/models/domain';
import { DomainsService } from 'src/api/services/DomainsService';

interface DomainDialogProps {
    isOpen:boolean
    existingDomain?:Domain
    onClose:(shouldRefresh:boolean) => void
}

const DomainActivationDialog: FC<DomainDialogProps> = ({isOpen = false, existingDomain, onClose}) => {
    const [isLoading, setIsLoading] = useState(false)
  
    const handleClose = () => {
        onClose(false)
    }; 

    const handleOnSave = async () => {
        setIsLoading(true)
        if(existingDomain?.active){
            await DomainsService.deactivate(existingDomain.id)
        } else {
            await DomainsService.activate(existingDomain.id)
        }
        setIsLoading(false)
        onClose(true)
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{ existingDomain?.active ? 'Are you sure you want to de-activate this domain?' : 'Are you sure you want to re-activate this domain?' }</DialogTitle>
            <Divider />
            <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <LoadingButton
                color={ existingDomain?.active ? 'error' : 'success'}
                loading={isLoading}
                onClick={handleOnSave}
            >{existingDomain?.active? 'Yes, de-activate it' : 'Yes, re-activate it'}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default DomainActivationDialog;

