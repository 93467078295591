import { Card, CardActions, CardContent, Checkbox, Divider, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import NewCampaignIcon from '@mui/icons-material/AddCircle';
import { InternalCampaign } from 'src/models/internalCampaign';
import GridFormInput from 'src/components/GridFormInput';
import { Box } from '@mui/system';

function PageHeader({
  searchTerms, 
  isSearching,
  onSearch,
  onClear,
  onNewCampaign,
}:{
  searchTerms:Partial<InternalCampaign>, 
  isSearching:boolean,
  onSearch:Function,
  onClear:Function,
  onNewCampaign:Function,
}) {
  const defaultValues = {
    name: '',
    user: '',
    external_campaign_id: '',
    active: true,
  };

  const methods = useForm({
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<Partial<InternalCampaign>> = async (values: Partial<InternalCampaign>) => {
    onSearch(methods.control._formValues)
  };

  return (
    <>
        <FormProvider {...methods} >
          <Card>
          <form onSubmit={methods.handleSubmit(onSubmitHandler)} >
          <CardContent>
      <Grid container spacing={1} columns={{ xs: 1, md: 3 }}>
        <GridFormInput
            label='Product Name'
            name='name'
          />
        <GridFormInput
          label='User'
          name='user'
        />
        <GridFormInput
          label='Sublytics CID'
          name='external_campaign_id'
        />
      </Grid>
      </CardContent>
      <Divider />
        <CardActions>
        <Box display="flex" width="100%" >
          <Box>
            <LoadingButton
              loading={isSearching}
              loadingPosition='start'
              startIcon={<NewCampaignIcon />}
              variant='contained'
              onClick={() => {
                onNewCampaign()
              }}
            >New Product</LoadingButton>
          </Box>
          <Box sx={{flex:'1 0 0'}}/>
            <FormGroup>
                <FormControlLabel 
                  control={<Checkbox 
                    name='active'
                    /*value={declineProfilesEnabled} 
                    checked={declineProfilesEnabled} */
                    onChange={(event, checked) => {
                      methods.control._formValues.active = !checked
                    }} 
                  />} 
                  label="Include inactive" 
                />
            </FormGroup>
            <LoadingButton
              loading={isSearching}
              loadingPosition='start'
              startIcon={<SearchIcon />}
              variant='outlined'
              type='submit'
              sx={{mr:1}}
            >Show Results</LoadingButton>
            <LoadingButton
              loading={isSearching}
              loadingPosition='start'
              startIcon={<CancelIcon />}
              variant='outlined'
              color='error'
              onClick={() => {
                methods.reset()
                onClear(methods.control._formValues)
              }}
            >Clear</LoadingButton>
        </Box>
        </CardActions>
      </form>
      </Card>
      </FormProvider>
    </>
  );
}

export default PageHeader;
