import { App } from "src/models/app";
import Api from "..";
import { AppRequest } from "src/models/appRequest";
import { PaginatedResponse } from "src/models/paginatedResponse";
import { PaginatedState } from "src/models/paginatedState";

export class AppsService {
  static async getApp(id:number):Promise<App> {
    try {
      const { data } = await Api.client.get<App>(`apps/${id}`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async getAll():Promise<App[]> {
    try {
      const { data } = await Api.client.get<App[]>(`apps`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async search({
    name = '',
  }, paginatedState: PaginatedState = { per_page: 10, page: 0 }):Promise<PaginatedResponse<App>> {
    var params = {
      name,
    }

    const queryString = Object.entries(paginatedState)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

    const baseUrl = `apps/search`;
    const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    try {
      const { data } = await Api.client.post<PaginatedResponse<App>>(urlWithQuery, params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async create(app:Partial<App>):Promise<App> {
    try {
      const { data } = await Api.client.post('apps', {
        name:app.name, 
        endpoint:app.endpoint, 
        config:app.config, 
        request_delay:app.request_delay,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async edit(app:Partial<App>):Promise<App> {
    try {
      const { data } = await Api.client.post('apps/edit', {
        id:app.id, 
        name:app.name, 
        endpoint:app.endpoint,
        config:app.config,
        request_delay:app.request_delay,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async delete(app:Partial<App>):Promise<void> {
    try {
      await Api.client.delete(`apps/${app.id}`);
    } catch (e) {
      throw e;
    }
  }

  static async getFailedRequests({
    per_page = 10,
    page = 0,
    id = 0,
  }):Promise<PaginatedResponse<AppRequest>> {
    var params = {
      'per_page': per_page,
      'page': page,
    };

    try {
      const { data } = await Api.client.post<PaginatedResponse<AppRequest>>(`apps/${id}/failed-requests`, params);
      return data;
    } catch (e) {
      throw e;
    }
  }
}