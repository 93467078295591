import { Box, Button } from '@mui/material';
import {FC, useState} from 'react'
import { ChromePicker } from 'react-color'

interface ColorPickerProps {
    defaultColor: string
    label: string
    onChange: (color:string) => void
}

const ColorPicker: FC<ColorPickerProps> = ({
    defaultColor,
    label,
    onChange,
}) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const [color, setColor] = useState<any>()

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  };

  const handleClose = () => {
    setDisplayColorPicker(false)
  };

  const popover = {
    position: 'absolute',
    zIndex: '99',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    zIndex: '1',
  }

    return (
      <Box sx={{position:'relative'}}>
        <Button onClick={ handleClick } startIcon={
            <Box sx={{backgroundColor:color ?? defaultColor, width: 10, height: 10}}></Box>
        }>Pick {label ?? 'Color'}</Button>
        { displayColorPicker ? <Box sx={ popover }>
          <Box sx={ cover } onClick={ handleClose }/>
          <Box sx={{zIndex:999, position:'relative'}}>
            <ChromePicker 
                color={ color ?? defaultColor }
                onChange ={ (e) => {
                    console.log(e)
                    setColor(e.hex)
                    onChange(e.hex)
                } }
            />
          </Box>
        </Box> : null }
      </Box>
    )
}

export default ColorPicker