export interface Template {
  id:number
  name:string
  key:string
  image:string
  steps:number
  content:TemplateContentItem[]
  offers_count:string
  pages_count:string
  active:number
  category:'offer'|'page'
}

export interface TemplateContentItem {
  key:string
  type:'text'|'textarea'|'image'|'variants'|'checkbox'|'children'|'richtext'|'color'|'terms'|'boolean'
  label:string
  placeholder?:string
  caption?:string
  children?:TemplateContentItem[]
}

export const TemplatePath = (template:Template) => {
  const apiBaseURL = new URL(window.config.API_BASE_URL);
  // This checks if the pathname is exactly '/api' or '/api/' and then replaces it with '/images'
  if (apiBaseURL.pathname === '/api' || apiBaseURL.pathname === '/api/') {
    apiBaseURL.pathname = '/assets/';
  }
  const assetsBasePath = apiBaseURL.href

  return `${assetsBasePath}${template.key}`;
}

export const TemplateThumbnail = (template:Template) => {
  if(!template.image) return undefined
  const apiBaseURL = new URL(window.config.API_BASE_URL);
  // This checks if the pathname is exactly '/api' or '/api/' and then replaces it with '/images'
  if (apiBaseURL.pathname === '/api' || apiBaseURL.pathname === '/api/') {
    apiBaseURL.pathname = '/assets/';
  }
  const assetsBasePath = apiBaseURL.href
  return `${assetsBasePath}${template.key}/images/${template.image}`;
}