import { Term } from "src/models/term";
import Api from "..";
import { PaginatedState } from "src/models/paginatedState";
import { PaginatedResponse } from "src/models/paginatedResponse";

export class TermsService {
  static async getAll():Promise<Term[]> {
    try {
      const { data } = await Api.client.get<Term[]>(`terms`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async search({
    name = '',
  }, paginatedState: PaginatedState = { per_page: 10, page: 0 }):Promise<PaginatedResponse<Term>> {
    var params = {
      name,
    }

    const queryString = Object.entries(paginatedState)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

    const baseUrl = `terms/search`;
    const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    try {
      const { data } = await Api.client.post<PaginatedResponse<Term>>(urlWithQuery, params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async create(term:Partial<Term>):Promise<Term> {
    try {
      const { data } = await Api.client.post('terms', {
        name:term.name, 
        content:term.content, 
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async edit(term:Partial<Term>):Promise<Term> {
    try {
      const { data } = await Api.client.post('terms/edit', {
        id:term.id, 
        name:term.name, 
        content:term.content, 
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async delete(term:Partial<Term>):Promise<void> {
    try {
      await Api.client.delete(`terms/${term.id}`);
    } catch (e) {
      throw e;
    }
  }

  static async refresh(term:Partial<Term>):Promise<Term> {
    try {
      const { data } = await Api.client.get(`terms/${term.id}/refresh`);
      return data.term
    } catch (e) {
      throw e;
    }
  }

  static async deactivate(termID:number):Promise<Term> {
    try {
      const { data } = await Api.client.post(`terms/${termID}/deactivate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async activate(termID:number):Promise<Term> {
    try {
      const { data } = await Api.client.post(`terms/${termID}/activate`);
      return data;
    } catch (e) {
      throw e;
    }
  }
}