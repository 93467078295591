import { Box, Card, CardActions, CardContent, Divider, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import TableChartIcon from '@mui/icons-material/TableChart';
import { InternalCampaign } from 'src/models/internalCampaign';
import GridFormInput from 'src/components/GridFormInput';
import { ProductSearchTerms } from '.';
import NewProductIcon from '@mui/icons-material/AddCircle';

function ProductsHeader({
  searchTerms, 
  isSearching,
  onSearch,
  onClear,
  onNewProduct,
}:{
  searchTerms:ProductSearchTerms, 
  isSearching:boolean,
  onSearch:Function,
  onClear:Function,
  onNewProduct:Function,
}) {
  const defaultValues = {
    name: '',
 };

  const methods = useForm({
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<ProductSearchTerms> = async (values: ProductSearchTerms) => {
    console.log('on submit')
    onSearch(methods.control._formValues)
  };

  return (
    <>
        <FormProvider {...methods} >
          <Card>
          <form onSubmit={methods.handleSubmit(onSubmitHandler)} >
          <CardContent>
      <Grid container spacing={1} columns={{ xs: 1, sm: 2, md: 4 }}>
        <GridFormInput
            label='Name'
            name='name'
          />
      </Grid>
      </CardContent>
      <Divider />
      <CardActions >
        <LoadingButton
          loadingPosition='start'
          startIcon={<NewProductIcon />}
          variant='contained'
          onClick={() => {
            onNewProduct()
          }}
        >New Product</LoadingButton>
        <Box sx={{flex: '1 1 auto'}} />
        <LoadingButton
          loading={isSearching}
          loadingPosition='start'
          startIcon={<SearchIcon />}
          variant='outlined'
          type='submit'
        >Show Results</LoadingButton>
        <LoadingButton
          loading={isSearching}
          loadingPosition='start'
          startIcon={<CancelIcon />}
          variant='outlined'
          color='error'
          onClick={() => {
            methods.reset()
            onClear(methods.control._formValues)
          }}
        >Clear</LoadingButton>
        </CardActions>
      </form>
      </Card>
      </FormProvider>
    </>
  );
}

export default ProductsHeader;
