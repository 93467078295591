import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, IconButton, Tooltip, Typography } from '@mui/material';

import { useLocation, useNavigate, useParams } from 'react-router';
import CustomerDetailsHeader from './CustomerDetailsHeader';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { useEffect, useState } from 'react';
import { Order } from 'src/models/order';
import { OrdersService } from 'src/api/services/OrdersService';
import { format } from 'date-fns';
import NotesTable from './NotesTable';
import { RebillOrder } from 'src/models/rebillOrder';
import RebillOrdersTable from './RebillOrdersTable';
import { Customer } from 'src/models/customer';
import { CustomersService } from 'src/api/services/CustomersService';
import { Transaction } from 'src/models/transaction';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import TransactionsTable from './TransactionsTable';
import { PaginatedState } from 'src/models/paginatedState';

function CustomerDetails() {
  const location = useLocation()
  const navigate = useNavigate()
  const { state } = location as any
  let { id } = useParams();

  const [customer, setCustomer] = useState<Customer>()
  const [isLoading, setIsLoading] = useState(false)
  const [transactions, setTransactions] = useState<PaginatedResponse<Transaction>>()
  const [paginatedState, setPaginatedState] = useState<PaginatedState>({per_page: 10, page: 0})

  useEffect(() => {
    if(state){
      setCustomer(state)
    } 
    refreshData()
  },[state, paginatedState])

  const refreshData = async () => {
    setIsLoading(true)
    CustomersService.getCustomer(parseInt(id)).then(customer => {
      setCustomer(customer)
    })
    CustomersService.transactions({id:parseInt(id)}, paginatedState).then(transactions => {
      setTransactions(transactions)
    })
    setIsLoading(false)
  }

  const handleUpdatedCustomer = async (updatedCustomer:Customer) => {
    setCustomer(updatedCustomer)
  }

  const onPageChange = (newPage: number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.page = newPage
    setPaginatedState(newPaginatedState)
  };

  const onRowsPerPageChange = (newRowsPerPage:number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.per_page = newRowsPerPage
    setPaginatedState(newPaginatedState)
  };

  return (
    <>
      <Helmet>
        <title>Customer Details - { `${id}` }</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
            <Box display="flex">
              <Box>
                <Typography variant="h3" component="h3" gutterBottom>
                  {`Customer ${id}`}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            { customer && <CustomerDetailsHeader customer={customer} onCustomerUpdated={handleUpdatedCustomer} /> }
          </Grid>
          <Grid item xs={12}>
            <Box display="flex">
              <Box>
                <Typography variant="subtitle2">
                Transactions
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {transactions && <TransactionsTable paginatedResponse={transactions} isRefreshing={isLoading} refreshData={refreshData} paginatedState={paginatedState} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange} />}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default CustomerDetails;
