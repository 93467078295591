import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, IconButton, Tooltip, Typography } from '@mui/material';

import { useLocation, useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { App } from 'src/models/app';
import { AppsService } from 'src/api/services/AppsService';
import { AppRequest } from 'src/models/appRequest';
import { PaginatedState } from 'src/models/paginatedState';
import AppRequestsTable from './AppRequestsTable';

function FailedRequests() {
  const location = useLocation()
  const navigate = useNavigate()
  const { state } = location as any
  let { id } = useParams();

  const [app, setApp] = useState<App>()
  const [isLoading, setIsLoading] = useState(false)
  const [paginatedResponse, setPaginatedResponse] = useState<PaginatedResponse<AppRequest>>()
  const [paginatedState, setPaginatedState] = useState<PaginatedState>({per_page: 10, page: 0})

  useEffect(() => {
    if(state){
      setApp(state)
    } 
    refreshData()
  },[state, paginatedState])

  const refreshData = async () => {
    setIsLoading(true)
    AppsService.getApp(parseInt(id)).then(app => {
      setApp(app)
    })
    AppsService.getFailedRequests({
      ...paginatedState,
      id:parseInt(id),
    }).then(response => {
      setPaginatedResponse(response)
    })
    setIsLoading(false)
  }

  const onPageChange = (newPage: number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.page = newPage
    setPaginatedState(newPaginatedState)
  };

  const onRowsPerPageChange = (newRowsPerPage:number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.per_page = newRowsPerPage
    setPaginatedState(newPaginatedState)
  };

  return (
    <>
      <Helmet>
        <title>Failed Requests - { `${app?.name ?? ''}` }</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
            <Box display="flex">
              <Box>
                <Typography variant="h3" component="h3" gutterBottom>
                  { `${app?.name ?? ''}` }
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>

          </Grid>
          <Grid item xs={12}>
            <Box display="flex">
              <Box>
                <Typography variant="subtitle2">
                Failed Requests
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <AppRequestsTable paginatedResponse={paginatedResponse} paginatedState={paginatedState} isRefreshing={isLoading} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default FailedRequests;
