import { Corporation } from "src/models/corporation";
import Api from "..";
import { PaginatedResponse } from "src/models/paginatedResponse";
import { PaginatedState } from "src/models/paginatedState";

export class CorporationsService {
  static async getAll():Promise<Corporation[]> {
    try {
      const { data } = await Api.client.get<Corporation[]>(`corporations`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async search({
    name = '',
  }, paginatedState: PaginatedState = { per_page: 10, page: 0 }):Promise<PaginatedResponse<Corporation>> {
    var params = {
      name,
    }

    const queryString = Object.entries(paginatedState)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

    const baseUrl = `corporations/search`;
    const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    try {
      const { data } = await Api.client.post<PaginatedResponse<Corporation>>(urlWithQuery, params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async create(corporation:Partial<Corporation>):Promise<Corporation> {
    try {
      const { data } = await Api.client.post('corporations', {
        name:corporation.name, 
        owner:corporation.owner, 
        address:corporation.address, 
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async edit(corporation:Partial<Corporation>):Promise<Corporation> {
    try {
      const { data } = await Api.client.post('corporations/edit', {
        id:corporation.id, 
        name:corporation.name, 
        owner:corporation.owner, 
        address:corporation.address, 
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async delete(corporation:Partial<Corporation>):Promise<void> {
    try {
      await Api.client.delete(`corporations/${corporation.id}`);
    } catch (e) {
      throw e;
    }
  }

  static async refresh(corporation:Partial<Corporation>):Promise<Corporation> {
    try {
      const { data } = await Api.client.get(`corporations/${corporation.id}/refresh`);
      return data.corporation
    } catch (e) {
      throw e;
    }
  }

  static async deactivate(corporationID:number):Promise<Corporation> {
    try {
      const { data } = await Api.client.post(`corporations/${corporationID}/deactivate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async activate(corporationID:number):Promise<Corporation> {
    try {
      const { data } = await Api.client.post(`corporations/${corporationID}/activate`);
      return data;
    } catch (e) {
      throw e;
    }
  }
}