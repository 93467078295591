import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';
import PageHeader from './PageHeader';
import { useEffect, useState } from 'react';
import { PaginatedState } from 'src/models/paginatedState';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { CampaignsService } from 'src/api/services/CampaignsService';
import DeclineProfilesTable from './DeclineProfilesTable';
import { DeclineProfile } from 'src/models/declineProfile';
import { DeclineProfileService } from 'src/api/services/DeclineProfileService';
import DeclineProfileDialog from './DeclineProfileDialog';
import { DeclineCode } from 'src/models/declineCode';
import { DeclineCodeService } from 'src/api/services/DeclineCodeService';

function ApplicationsDeclineProfiles() {
  const [paginatedResponse, setPaginatedResponse] = useState<DeclineProfile[]>([])
  const [paginatedState, setPaginatedState] = useState<PaginatedState>({per_page: 10, page: 0})
  const [searchTerms, setSearchTerms] = useState<Partial<DeclineProfile>>({
    name: '',
  })

  const [isSearching, setIsSearching] = useState(false)
  const [declineCodes, setDeclineCodes] = useState<DeclineCode[]>([])

  const onSearch = async (terms:Partial<DeclineProfile>) => {
    console.log(terms)
    const newTerms = {...terms}
    setSearchTerms(newTerms)
  }

  const onClear = async (terms:Partial<DeclineProfile>) => {
    setSearchTerms(terms)
  }

  const refreshData = async () => {
    setIsSearching(true)
    try{
      const response = await DeclineProfileService.search({
        ...searchTerms,
      })
      setPaginatedResponse(response)
      if (declineCodes.length <= 0) {
        const dc = await DeclineCodeService.getAll();
        setDeclineCodes(dc)
      }
    } catch(e){}
    setIsSearching(false)
  }

  useEffect(() => {
    refreshData()
  },[searchTerms, paginatedState])

  const onPageChange = (newPage: number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.page = newPage
    setPaginatedState(newPaginatedState)
  };

  const onRowsPerPageChange = (newRowsPerPage:number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.per_page = newRowsPerPage
    setPaginatedState(newPaginatedState)
  };

  const [isDeclineProfileDialogOpen, setIsDeclineProfileDialogOpen] = useState(false)
  const [existingDeclineProfile, setExistingDeclineProfile] = useState<DeclineProfile>()

  const onNewDeclineProfile = () => {
    setExistingDeclineProfile(undefined)
    setIsDeclineProfileDialogOpen(true)
  }

  const onEditDeclineProfile = (declineProfile:DeclineProfile) => {
    setExistingDeclineProfile(declineProfile)
    setIsDeclineProfileDialogOpen(true)
  }

  const onDeclineProfileDialogClose = (shouldRefresh:boolean) => {
    setIsDeclineProfileDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  return (
    <>
      <Helmet>
        <title>Decline Profiles</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
            <PageHeader 
              searchTerms={searchTerms} 
              isSearching={isSearching} 
              onSearch={onSearch} 
              onClear={onClear} 
              onNewDeclineProfile={onNewDeclineProfile} 
            />
          </Grid>
          <Grid item xs={12}>
            <DeclineProfilesTable 
              paginatedResponse={paginatedResponse} 
              paginatedState={paginatedState} 
              isRefreshing={isSearching} 
              onPageChange={onPageChange} 
              onRowsPerPageChange={onRowsPerPageChange}
              onEditDeclineProfile={onEditDeclineProfile}
            />
          </Grid>
        </Grid>
      </Container>
      <DeclineProfileDialog isOpen={isDeclineProfileDialogOpen} onClose={onDeclineProfileDialogClose} existingDeclineProfile={existingDeclineProfile} declineCodes={declineCodes} />
    </>
  );
}

export default ApplicationsDeclineProfiles;
