import { FC, ChangeEvent } from 'react';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  Backdrop,
  styled,
  CircularProgress,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { PaginatedState } from 'src/models/paginatedState';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Product } from 'src/models/product';
import { PaginatedResponse } from 'src/models/paginatedResponse';

interface ProductsTableProps {
  className?: string
  paginatedResponse?: PaginatedResponse<Product>
  paginatedState: PaginatedState
  isRefreshing:boolean
  onPageChange:(page:number) => void
  onRowsPerPageChange:(rowsPerPage:number) => void
  onEditProduct:(product:Product) => void
  onDeactivateProduct:(product:Product) => void
  onActivateProduct:(product:Product) => void
  onRefreshProduct:(product:Product) => void
  isRefreshingProduct:boolean
}

const LimitedBackdrop = styled(Backdrop)(
  () => `
    position: absolute;
    z-index: 1;
`
);

const ProductsTable: FC<ProductsTableProps> = ({
  paginatedResponse, 
  paginatedState,
  isRefreshing = false, 
  onPageChange, 
  onRowsPerPageChange,
  onEditProduct,
  onDeactivateProduct,
  onActivateProduct,
  onRefreshProduct,
  isRefreshingProduct = false, 
}) => {
  const navigate = useNavigate()

  const handlePageChange = async (event: any, newPage: number) => {
    onPageChange(newPage)
  };

  const handleLimitChange = async (event: ChangeEvent<HTMLInputElement>) => {
    onRowsPerPageChange(parseInt(event.target.value))
  };

  return (
    <Card sx={{
      position:'relative'
    }}>
      <LimitedBackdrop open={isRefreshing}><CircularProgress size={'5rem'} /></LimitedBackdrop>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Shipping Price</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedResponse?.data.map((item) => {
              return (
                <TableRow
                  key={item.id}
                  >
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.id}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.price}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.shipping_price}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box display='flex'>
                    <LoadingButton
                      onClick={() => {
                        onEditProduct(item)
                      }}
                    >Edit</LoadingButton>
                    </Box>
                  </TableCell>

                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={paginatedResponse?.total ?? 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={paginatedState.page}
          rowsPerPage={paginatedState.per_page}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default ProductsTable;
