import { DeclineAttempt } from "src/models/declineAttempt";
import { DeclineProfile } from "src/models/declineProfile";
import { Flow } from "src/models/flow";
import { InternalCampaign } from "src/models/internalCampaign";
import { PaginatedResponse } from "src/models/paginatedResponse";
import Api from "..";

export class DeclineProfileService {
  static async getAll():Promise<DeclineProfile[]> {
    try {
      const { data } = await Api.client.get<DeclineProfile[]>(`decline-profiles`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async search({
    name = '',
  }):Promise<DeclineProfile[]> {
    var params = {
      name,
    }

    try {
      const { data } = await Api.client.post<DeclineProfile[]>('decline-profiles/search', params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async create(declineProfile:Partial<DeclineProfile>, declineReasons:string[], declineAttempts:Partial<DeclineAttempt>[]):Promise<DeclineProfile> {
    try {
      const { data } = await Api.client.post('decline-profiles', {
        name:declineProfile.name, 
        enable_first_charge:declineProfile.enable_first_charge, 
        enable_rebills:declineProfile.enable_rebills, 
        decline_reasons: declineReasons,
        decline_attempts: declineAttempts,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async edit(declineProfile:Partial<DeclineProfile>, declineReasons:string[], declineAttempts:Partial<DeclineAttempt>[]):Promise<DeclineProfile> {
    try {
      const { data } = await Api.client.post('decline-profiles/edit', {
        id:declineProfile.id, 
        name:declineProfile.name, 
        enable_first_charge:declineProfile.enable_first_charge, 
        enable_rebills:declineProfile.enable_rebills, 
        decline_reasons: declineReasons,
        decline_attempts: declineAttempts,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async delete(declineProfile:Partial<DeclineProfile>):Promise<void> {
    try {
      await Api.client.delete(`decline-profiles/${declineProfile.id}`);
    } catch (e) {
      throw e;
    }
  }
}