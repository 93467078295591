
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Alert, Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeclineProfileService } from 'src/api/services/DeclineProfileService';
import { DeclineAttempt } from 'src/models/declineAttempt';
import DeclineAttemptSection from './DeclineAttemptSection';
import NewDeclineAttemptIcon from '@mui/icons-material/AddCircle';
import { DeclineProfile } from 'src/models/declineProfile';
import { ApiException } from 'src/models/apiError';
import { DeclineCode } from 'src/models/declineCode';

interface DeclineProfileDialogProps {
    isOpen: boolean
    existingDeclineProfile?: DeclineProfile
    onClose: (shouldRefresh: boolean) => void
    declineCodes:DeclineCode[]
}

const DeclineProfileDialog: FC<DeclineProfileDialogProps> = ({ isOpen = false, existingDeclineProfile, onClose, declineCodes }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [rebillsEnabled, setRebillsEnabled] = useState(true)
    const [firstChargeEnabled, setFirstChargeEnabled] = useState(true)
    const [declineAttempts, setDeclineAttempts] = useState<Partial<DeclineAttempt>[]>([])
    const [declineReasons, setDeclineReasons] = useState<string[]>([]);

    const handleClose = () => {
        onClose(false)
    };

    useEffect(() => {
        if (existingDeclineProfile) {
            setName(existingDeclineProfile.name)
            setRebillsEnabled(existingDeclineProfile.enable_rebills == 1 ? true : false)
            setFirstChargeEnabled(existingDeclineProfile.enable_first_charge == 1 ? true : false)
            setDeclineAttempts(existingDeclineProfile.decline_attempts)
            setDeclineReasons(existingDeclineProfile.decline_codes.map(dc => `${dc.id}`))
        } else {
            setName('')
            setRebillsEnabled(true)
            setFirstChargeEnabled(true)
            let declineAttempt:Partial<DeclineAttempt> = {
                external_campaign_id: '',
                external_offer_id: '',
                hours_after_last_decline: '',
            }
            setDeclineAttempts([declineAttempt])
            setDeclineReasons([])
        }
        setError('')
    }, [existingDeclineProfile])

    const handleOnDelete = async () => {
        setIsLoading(true)
        try{
            await DeclineProfileService.delete({
                id: existingDeclineProfile.id,
            })
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleOnSave = async () => {
        setIsLoading(true)
        setError('')
        console.log(declineAttempts)
        try{
            if (existingDeclineProfile) {
                //Edit
                await DeclineProfileService.edit({
                    id: existingDeclineProfile.id,
                    name,
                    enable_first_charge:firstChargeEnabled ? 1 : 0,
                    enable_rebills:rebillsEnabled ? 1 : 0,
                }, declineReasons, declineAttempts)
            } else {
                //Create
                await DeclineProfileService.create({
                    name,
                    enable_first_charge:firstChargeEnabled ? 1 : 0,
                    enable_rebills:rebillsEnabled ? 1 : 0,
                }, declineReasons, declineAttempts)
            }
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleNewDeclineAttempt = () => {
        let declineAttempt:Partial<DeclineAttempt> = {
            external_campaign_id: '',
            external_offer_id: '',
            hours_after_last_decline: '',
        }
        let newDeclineAttempts = [...declineAttempts, declineAttempt]
        setDeclineAttempts(newDeclineAttempts)
    };

    const handleRemoveDeclineAttempt = (position:number) => {
        let newDeclineAttempts = [...declineAttempts]
        newDeclineAttempts.splice(position-1, 1)
        setDeclineAttempts(newDeclineAttempts)
    }

    const handleChangeDeclineAttempt = (position:number, declineAttempt:DeclineAttempt) => {
        let newDeclineAttempts = [...declineAttempts]
        newDeclineAttempts[position-1] = declineAttempt
        setDeclineAttempts(newDeclineAttempts)
    }

    const handleDeclineReasonsChange = (event: SelectChangeEvent<typeof declineReasons>) => {
        const {
          target: { value },
        } = event;
        // On autofill we get a stringified value.
        const dr = typeof value === 'string' ? value.split(',') : value
        console.log(dr)
        setDeclineReasons(dr)
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{existingDeclineProfile ? 'Edit Decline Profile' : 'Create Decline Profile'}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1} columns={{ xs: 1, sm: 3 }} mb={1}>
                    <Grid item xs={1}>
                        <TextField
                            autoFocus
                            label="Decline Profile Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox value={firstChargeEnabled} checked={firstChargeEnabled} onChange={(event, checked) => {
                                setFirstChargeEnabled(checked)
                            }} />} label="Enable First Charge" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={1}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox value={rebillsEnabled} checked={rebillsEnabled} onChange={(event, checked) => {
                                setRebillsEnabled(checked)
                            }} />} label="Enable Rebills" />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Divider />
                <FormControl sx={{
                    width:'100%',
                    mt: 1,
                    mb: 1,
                }}>
                    <InputLabel id="demo-multiple-chip-label">Decline Reasons</InputLabel>
                    <Select
                        multiple
                        value={declineReasons}
                        onChange={handleDeclineReasonsChange}
                        input={<OutlinedInput label="Decline Reasons" />}
                        renderValue={(selected) => { 
                            return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => {
                                const declineCode = declineCodes.find(dc => dc.id == parseInt(value))
                                return (
                                <Chip key={value} label={declineCode.reason} />
                            )})}
                            </Box>
                        )}}
                        //MenuProps={MenuProps}
                    >
                    {declineCodes.map((declineCode) => (
                        <MenuItem
                        key={declineCode.id}
                        value={`${declineCode.id}`}
                        >
                        {declineCode.reason}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                {declineAttempts.map((declineAttempt, index) => <DeclineAttemptSection 
                    key={index} 
                    position={index+1} 
                    declineAttempt={declineAttempt} 
                    onRemoveDeclineAttempt={handleRemoveDeclineAttempt} 
                    onChangeDeclineAttempt={handleChangeDeclineAttempt}
                />)}
                <LoadingButton
                    sx={{mt:1}}
                    startIcon={<NewDeclineAttemptIcon />}
                    variant='contained'                
                    onClick={handleNewDeclineAttempt}
                >Add Decline Attempt</LoadingButton>
                { error && <Alert severity="error">
                    {error}
                </Alert> }
            </DialogContent>
            <Divider />
            <DialogActions>
                { existingDeclineProfile && <LoadingButton
                    color='error'
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<DeleteIcon />}
                    onClick={handleOnDelete}
                >Delete</LoadingButton> }
                <Box sx={{flex: '1 0 0'}} />
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    onClick={handleOnSave}
                >{existingDeclineProfile ? 'Save' : 'Create Decline Profile'}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default DeclineProfileDialog;

