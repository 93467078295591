import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';
import TransactionsTable from './TransactionsTable';
import PageHeader from './PageHeader';
import { useEffect, useState } from 'react';
import { Order } from 'src/models/order';
import { OrdersService } from 'src/api/services/OrdersService';
import { PaginatedState } from 'src/models/paginatedState';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { InternalCampaign } from 'src/models/internalCampaign';
import { CampaignsService } from 'src/api/services/CampaignsService';
import { CustomersService } from 'src/api/services/CustomersService';
import { Customer } from 'src/models/customer';
import { Transaction } from 'src/models/transaction';
import { TransactionsService } from 'src/api/services/TransactionsService';

function ApplicationsTransactions() {
  const [paginatedResponse, setPaginatedResponse] = useState<PaginatedResponse<Transaction>>()
  const [paginatedState, setPaginatedState] = useState<PaginatedState>({per_page: 10, page: 0})
  const [searchTerms, setSearchTerms] = useState<Partial<Order> & Partial<Transaction>>({})
  const [status, setStatus] = useState('')
  const [createdFrom, setCreatedFrom] = useState('')
  const [createdTo, setCreatedTo] = useState('')
  const [campaigns, setCampaigns] = useState<InternalCampaign[]>([])

  const [isSearching, setIsSearching] = useState(false)
  const [isExporting, setIsExporting] = useState(false)

  const onSearch = async (terms:Partial<Order> & Partial<Transaction>) => {
    console.log(terms)
    const newTerms = {...terms}
    setSearchTerms(newTerms)
  }

  const onClear = async (terms:Partial<Order> & Partial<Transaction>) => {
    setSearchTerms(terms)
  }

  const onExport = async () => {
    setIsExporting(true)
    setIsExporting(false)
  }

  const refreshData = async () => {
    setIsSearching(true)
    const response = await TransactionsService.search({
      ...searchTerms,
    },paginatedState)
    setPaginatedResponse(response)
    setIsSearching(false)
  }

  useEffect(() => {
    refreshData()
  },[searchTerms, paginatedState])

  const onPageChange = (newPage: number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.page = newPage
    setPaginatedState(newPaginatedState)
  };

  const onRowsPerPageChange = (newRowsPerPage:number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.per_page = newRowsPerPage
    setPaginatedState(newPaginatedState)
  };

  return (
    <>
      <Helmet>
        <title>Customers</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
            <PageHeader searchTerms={searchTerms} isSearching={isSearching} isExporting={isExporting} onSearch={onSearch} onClear={onClear} onExport={onExport} campaigns={campaigns} />
          </Grid>
          <Grid item xs={12}>
            <TransactionsTable paginatedResponse={paginatedResponse} paginatedState={paginatedState} isRefreshing={isSearching} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ApplicationsTransactions;
