import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { PaginatedState } from 'src/models/paginatedState';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { CorporationsService } from 'src/api/services/CorporationsService';
import CorporationsTable from './CorporationsTable';
import { Corporation } from 'src/models/corporation';
import CorporationDialog from './CorporationDialog';
import { DeclineCode } from 'src/models/declineCode';
import NewCorporationIcon from '@mui/icons-material/AddCircle';
import { LoadingButton } from '@mui/lab';

function ApplicationsCorporations() {
  const [paginatedResponse, setPaginatedResponse] = useState<PaginatedResponse<Corporation>>()
  const [paginatedState, setPaginatedState] = useState<PaginatedState>({per_page: 10, page: 0})
  const [searchTerms, setSearchTerms] = useState<Partial<Corporation>>({
    name: '',
  })

  const [isSearching, setIsSearching] = useState(false)
  const [serverIP, setServerIP] = useState<string>()

  const onSearch = async (terms:Partial<Corporation>) => {
    const newTerms = {...terms}
    setSearchTerms(newTerms)
  }

  const onClear = async (terms:Partial<Corporation>) => {
    setSearchTerms(terms)
  }

  const refreshData = async () => {
    setIsSearching(true)
    try{
      const response = await CorporationsService.search({
        ...searchTerms,
      }, paginatedState)
      setPaginatedResponse(response)
    } catch(e){}
    setIsSearching(false)
  }

  useEffect(() => {
    refreshData()
  },[searchTerms, paginatedState])

  const onPageChange = (newPage: number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.page = newPage
    setPaginatedState(newPaginatedState)
  };

  const onRowsPerPageChange = (newRowsPerPage:number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.per_page = newRowsPerPage
    setPaginatedState(newPaginatedState)
  };

  const [isCorporationDialogOpen, setIsCorporationDialogOpen] = useState(false)
  const [existingCorporation, setExistingCorporation] = useState<Corporation>()
  const [isRefreshingCorporation, setIsRefreshingCorporation] = useState(false)

  const onNewCorporation = () => {
    setExistingCorporation(undefined)
    setIsCorporationDialogOpen(true)
  }

  const onEditCorporation = (corporation:Corporation) => {
    setExistingCorporation(corporation)
    setIsCorporationDialogOpen(true)
  }

  const onCorporationDialogClose = (shouldRefresh:boolean) => {
    setIsCorporationDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  const onRefreshCorporation = async (corporation:Corporation) => {
    setIsRefreshingCorporation(true)
    const refreshedCorporation = await CorporationsService.refresh(corporation)
    if(refreshedCorporation){
      await refreshData()
    }
    setIsRefreshingCorporation(false)
  }

  const [isCorporationActivationDialogOpen, setIsCorporationActivationDialogOpen] = useState(false)

  const onActivateCorporation = (corporation:Corporation) => {
    setExistingCorporation(corporation)
    setIsCorporationActivationDialogOpen(true)
  }

  const onDeactivateCorporation = (corporation:Corporation) => {
    setExistingCorporation(corporation)
    setIsCorporationActivationDialogOpen(true)
  }

  const onCorporationActivationDialogClose = (shouldRefresh:boolean) => {
    setIsCorporationActivationDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  return (
    <>
      <Helmet>
        <title>Corporations</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
            {/*<PageHeader 
              searchTerms={searchTerms} 
              isSearching={isSearching} 
              onSearch={onSearch} 
              onClear={onClear} 
              onNewCorporation={onNewCorporation} 
            />*/}
            <LoadingButton
              loading={isSearching}
              loadingPosition='start'
              startIcon={<NewCorporationIcon />}
              variant='contained'
              onClick={() => {
                onNewCorporation()
              }}
            >New Corporation</LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <CorporationsTable 
              paginatedResponse={paginatedResponse} 
              paginatedState={paginatedState} 
              isRefreshing={isSearching} 
              onPageChange={onPageChange} 
              onRowsPerPageChange={onRowsPerPageChange}
              onEditCorporation={onEditCorporation}
              onActivateCorporation={onActivateCorporation}
              onDeactivateCorporation={onDeactivateCorporation}
              onRefreshCorporation={onRefreshCorporation}
              isRefreshingCorporation={isRefreshingCorporation} 
            />
          </Grid>
        </Grid>
      </Container>
      <CorporationDialog 
        isOpen={isCorporationDialogOpen} 
        onClose={onCorporationDialogClose} 
        existingCorporation={existingCorporation} 
        serverIP={serverIP}
      />
    </>
  );
}

export default ApplicationsCorporations;
