import { Card, CardActions, CardContent, Divider, Grid, MenuItem, Select, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Order } from 'src/models/order';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import FormInput from 'src/components/FormInput';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import TableChartIcon from '@mui/icons-material/TableChart';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { OrdersService } from 'src/api/services/OrdersService';
import { InternalCampaign } from 'src/models/internalCampaign';
import GridFormInput from 'src/components/GridFormInput';
import { Customer } from 'src/models/customer';
import { Transaction } from 'src/models/transaction';

function PageHeader({
  searchTerms, 
  isSearching,
  isExporting,
  onSearch,
  onClear,
  onExport,
  campaigns,
}:{
  searchTerms:Partial<Order> & Partial<Transaction>, 
  isSearching:boolean,
  isExporting:boolean,
  onSearch:Function,
  onClear:Function,
  onExport:Function,
  campaigns:InternalCampaign[],
}) {
  const defaultValues = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };

  const methods = useForm({
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<Partial<Order> & Partial<Transaction>> = async (values: Partial<Order> & Partial<Transaction>) => {
    console.log('on submit')
    onSearch(methods.control._formValues)
  };

  const handleCampaignChange = (event) => {

  };

  return (
    <>
        <FormProvider {...methods} >
          <Card>
          <form onSubmit={methods.handleSubmit(onSubmitHandler)} >
          <CardContent>
      <Grid container spacing={1} columns={{ xs: 1, sm: 2, md: 4 }}>
        <GridFormInput
            label='Transaction ID'
            name='id'
          />
        <GridFormInput
            label='First Name'
            name='firstName'
          />
        <GridFormInput
          label='Last Name'
          name='lastName'
        />
        <GridFormInput
          label='Email'
          type='email'
          name='email'
        />
        <GridFormInput
          label='Phone'
          name='phone'
        />
      </Grid>
      </CardContent>
      <Divider />
        <CardActions sx={{ justifyContent:'flex-end' }} >
        <LoadingButton
          loading={isSearching}
          loadingPosition='start'
          startIcon={<SearchIcon />}
          variant='outlined'
          type='submit'
        >Show Results</LoadingButton>
        <LoadingButton
          loading={isSearching}
          loadingPosition='start'
          startIcon={<CancelIcon />}
          variant='outlined'
          color='error'
          onClick={() => {
            methods.reset()
            onClear(methods.control._formValues)
          }}
        >Clear</LoadingButton>
        <LoadingButton
          loading={isExporting}
          loadingPosition='start'
          startIcon={<TableChartIcon />}
          variant='outlined'
          onClick={() => onExport()}
        >CSV</LoadingButton>
        </CardActions>
      </form>
      </Card>
      </FormProvider>
    </>
  );
}

export default PageHeader;
