
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Offer } from 'src/models/offer';
import { OffersService } from 'src/api/services/OffersService';

interface OfferDialogProps {
    isOpen:boolean
    existingOffer?:Offer
    onClose:(shouldRefresh:boolean) => void
}

const OfferActivationDialog: FC<OfferDialogProps> = ({isOpen = false, existingOffer, onClose}) => {
    const [isLoading, setIsLoading] = useState(false)
  
    const handleClose = () => {
        onClose(false)
    }; 

    const handleOnSave = async () => {
        setIsLoading(true)
        if(existingOffer?.active){
            await OffersService.deactivate(existingOffer.id)
        } else {
            await OffersService.activate(existingOffer.id)
        }
        setIsLoading(false)
        onClose(true)
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{ existingOffer?.active ? 'Are you sure you want to de-activate this offer?' : 'Are you sure you want to re-activate this offer?' }</DialogTitle>
            <Divider />
            <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <LoadingButton
                color={ existingOffer?.active ? 'error' : 'success'}
                loading={isLoading}
                onClick={handleOnSave}
            >{existingOffer?.active? 'Yes, de-activate it' : 'Yes, re-activate it'}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default OfferActivationDialog;

