import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';
import PageHeader from './PageHeader';
import { useEffect, useState } from 'react';
import { PaginatedState } from 'src/models/paginatedState';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { InternalCampaign } from 'src/models/internalCampaign';
import { CampaignsService } from 'src/api/services/CampaignsService';
import CampaignsTable from './CampaignsTable';
import CampaignDialog from './CampaignDialog';
import CampaignActivationDialog from './CampaignActivationDialog';
import { DeclineProfile } from 'src/models/declineProfile';
import { DeclineProfileService } from 'src/api/services/DeclineProfileService';
import CampaignDuplicationDialog from './CampaignDuplicationDialog';

function ApplicationsCampaigns() {
  const [paginatedResponse, setPaginatedResponse] = useState<PaginatedResponse<InternalCampaign>>()
  const [paginatedState, setPaginatedState] = useState<PaginatedState>({per_page: 10, page: 0})
  const [searchTerms, setSearchTerms] = useState<Partial<InternalCampaign>>({
    name: '',
    user: '',
    external_campaign_id: '',
    active: true,
  })

  const [isSearching, setIsSearching] = useState(false)
  const [declineProfiles, setDeclineProfiles] = useState<DeclineProfile[]>([])

  const onSearch = async (terms:Partial<InternalCampaign>) => {
    console.log(terms)
    const newTerms = {...terms}
    setSearchTerms(newTerms)
  }

  const onClear = async (terms:Partial<InternalCampaign>) => {
    setSearchTerms(terms)
  }

  const refreshData = async () => {
    setIsSearching(true)
    try{
      const response = await CampaignsService.search({
        ...searchTerms,
      }, paginatedState)
      setPaginatedResponse(response)
      if(declineProfiles.length <= 0){
        const dp = await DeclineProfileService.getAll();
        setDeclineProfiles(dp)
      }
    } catch(e){}
    setIsSearching(false)
  }

  useEffect(() => {
    refreshData()
  },[searchTerms, paginatedState])

  const onPageChange = (newPage: number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.page = newPage
    setPaginatedState(newPaginatedState)
  };

  const onRowsPerPageChange = (newRowsPerPage:number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.per_page = newRowsPerPage
    setPaginatedState(newPaginatedState)
  };

  const [isCampaignDialogOpen, setIsCampaignDialogOpen] = useState(false)
  const [isDuplicatingCampaignDialogOpen, setIsDuplicatingCampaignDialogOpen] = useState(false)
  const [isCampaignActivationDialogOpen, setIsCampaignActivationDialogOpen] = useState(false)
  const [existingCampaign, setExistingCampaign] = useState<InternalCampaign>()

  const onNewCampaign = () => {
    setExistingCampaign(undefined)
    setIsCampaignDialogOpen(true)
  }

  const onEditCampaign = (internalCampaign:InternalCampaign) => {
    setExistingCampaign(internalCampaign)
    setIsCampaignDialogOpen(true)
  }

  const onDuplicateCampaign = (internalCampaign:InternalCampaign) => {
    setExistingCampaign(internalCampaign)
    setIsDuplicatingCampaignDialogOpen(true)
  }

  const onActivateCampaign = (internalCampaign:InternalCampaign) => {
    setExistingCampaign(internalCampaign)
    setIsCampaignActivationDialogOpen(true)
  }

  const onDeactivateCampaign = (internalCampaign:InternalCampaign) => {
    setExistingCampaign(internalCampaign)
    setIsCampaignActivationDialogOpen(true)
  }

  const onCampaignDialogClose = (shouldRefresh:boolean) => {
    setIsCampaignDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  const onCampaignActivationDialogClose = (shouldRefresh:boolean) => {
    setIsCampaignActivationDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  const onDuplicatingCampaignDialogClose = (shouldRefresh:boolean) => {
    setIsDuplicatingCampaignDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  return (
    <>
      <Helmet>
        <title>Products</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
            <PageHeader searchTerms={searchTerms} isSearching={isSearching} onSearch={onSearch} onClear={onClear} onNewCampaign={onNewCampaign} />
          </Grid>
          <Grid item xs={12}>
            <CampaignsTable 
              paginatedResponse={paginatedResponse} 
              paginatedState={paginatedState} 
              isRefreshing={isSearching} 
              onPageChange={onPageChange} 
              onRowsPerPageChange={onRowsPerPageChange}
              onEditCampaign={onEditCampaign}
              onDuplicateCampaign={onDuplicateCampaign}
              onActivateCampaign={onActivateCampaign}
              onDeactivateCampaign={onDeactivateCampaign}
            />
          </Grid>
        </Grid>
      </Container>
      <CampaignDialog 
        isOpen={isCampaignDialogOpen} 
        onClose={onCampaignDialogClose} 
        existingCampaign={existingCampaign} 
        declineProfiles={declineProfiles}
      />
      <CampaignActivationDialog 
        isOpen={isCampaignActivationDialogOpen} 
        onClose={onCampaignActivationDialogClose} 
        existingCampaign={existingCampaign} 
      />
      <CampaignDuplicationDialog
        isOpen={isDuplicatingCampaignDialogOpen} 
        onClose={onDuplicatingCampaignDialogClose} 
        existingCampaign={existingCampaign} 
      />
    </>
  );
}

export default ApplicationsCampaigns;
