import { DeclineCode } from "src/models/declineCode";
import Api from "..";

export class DeclineCodeService {
  static async getAll():Promise<DeclineCode[]> {
    try {
      const { data } = await Api.client.get<DeclineCode[]>(`decline-codes`);
      return data;
    } catch (e) {
      throw e;
    }
  }
}