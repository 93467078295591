import { PaginatedResponse } from "src/models/paginatedResponse";
import { Transaction } from "src/models/transaction";
import Api from "..";
import { PaginatedState } from "src/models/paginatedState";

export class TransactionsService {
  static async getTransaction(id:number):Promise<Transaction> {
    try {
      const { data } = await Api.client.get<Transaction>(`transactions/${id}`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async transactions(paginatedState: PaginatedState = { per_page: 10, page: 0 }):Promise<PaginatedResponse<Transaction>> {
    const queryString = Object.entries(paginatedState)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

    const baseUrl = `transactions`;
    const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;    

    try {
      const { data } = await Api.client.get<PaginatedResponse<Transaction>>(urlWithQuery);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async search({
    id = '',
    firstName = '',
    lastName = '',
    email = '',
    phone = '',
    address = '',
    city = '',
    state = '',
    zip = '',
    status = '',
    campaignId = '',
    createdFrom = '',
    createdTo = '',
  }, paginatedState: PaginatedState = { per_page: 10, page: 0 }):Promise<PaginatedResponse<Transaction>> {
    var params = {
      'id': id,
      'firstName': firstName,
      'lastName': lastName,
      'email': email,
      'phone': phone,
      'address': address,
      'city': city,
      'state': state,
      'zip': zip,
      'status': status,
      'campaignId': campaignId,
      'createdFrom': createdFrom,
      'createdTo': createdTo,
    };

    const queryString = Object.entries(paginatedState)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

    const baseUrl = `transactions/search`;
    const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    try {
      const { data } = await Api.client.post<PaginatedResponse<Transaction>>(urlWithQuery, params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async edit(transaction:Partial<Transaction>):Promise<Transaction> {
    try {
      const { data } = await Api.client.post('transactions/edit', {
        id:transaction.id, 
        external_offer_id:transaction.external_offer_id, 
        charge_at:transaction.charge_at, 
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async cancelCharge(transactionID:string, {cancellationNotes}:{cancellationNotes:string}):Promise<Transaction> {
    try {
      const { data } = await Api.client.post<Transaction>(`transactions/${transactionID}/cancel`,
          {'cancellation_notes': cancellationNotes})
      return data
    } catch (e) {
      throw e;
    }
  }   

  static async resumeCharge(orderID:string):Promise<Transaction> {
    try {
      const { data } = await Api.client.post<Transaction>(`transactions/${orderID}/resume`)
      return data
    } catch (e) {
      throw e;
    }
  }  

}