
import { FC, ChangeEvent, useState, useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Checkbox, Chip, Divider, FormControlLabel, FormGroup, Grid, IconButton, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import ResumeIcon from '@mui/icons-material/Check';
import { InternalCampaign } from 'src/models/internalCampaign';
import { CampaignsService } from 'src/api/services/CampaignsService';
import { Flow } from 'src/models/flow';
import { Transaction, TransactionWasBilled } from 'src/models/transaction';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { format } from 'date-fns';
import '../../../extensions'
import { TransactionsService } from 'src/api/services/TransactionsService';

interface TransactionDialogProps {
    isOpen: boolean
    existingTransaction?: Transaction
    onClose: (shouldRefresh: boolean) => void
}

const TransactionDialog: FC<TransactionDialogProps> = ({ isOpen = false, existingTransaction, onClose }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [offerID, setOfferID] = useState<string>('')
    const [chargeDate, setChargeDate] = useState<string>('')

    const [isCancellingCharge, setIsCancellingCharge] = useState(false);
    const [isResumingCharge, setIsResumingCharge] = useState(false);
    const [openCancelCharge, setOpenCancelCharge] = useState(false);
    const [openResumeCharge, setOpenResumeCharge] = useState(false);
    const [cancellationNotes, setCancellationNotes] = useState('');
  
    const handleClose = () => {
        onClose(false)
    };

    useEffect(() => {
        if (existingTransaction) {
            console.log(existingTransaction)
            setOfferID(existingTransaction.external_offer_id.toString())
            setChargeDate(existingTransaction.charge_at)
        } else {
            setOfferID('')
            setChargeDate('')
        }
    }, [existingTransaction])

    const handleOnSave = async () => {
        setIsLoading(true)
        try{
        if (existingTransaction) {
            //Edit
            await TransactionsService.edit({
                id: existingTransaction.id,
                external_offer_id: offerID,
                charge_at: chargeDate,
            })
        } else {
            //Create
            /*await CampaignsService.create({
                name,
                user: user,
            })*/
        }
        onClose(true)
        }catch(e) {

        }
        setIsLoading(false)
    }

    const handleCloseCharge = () => {
        setOpenCancelCharge(false)
        setOpenResumeCharge(false)
    }

    const cancelCharge = async () => {
        setOpenCancelCharge(true);
    }

    const confirmChargeCancellation = async () => {
        setIsCancellingCharge(true)
        try{
        const updatedTransaction = await TransactionsService.cancelCharge(existingTransaction.id, {
            cancellationNotes:cancellationNotes.length > 0 
            ? cancellationNotes 
            : 'Cancelled by customer support'})
        onClose(true)
        handleCloseCharge()
        } catch(e){
        console.error(e)
        }
        setIsCancellingCharge(false)
    }

    const resumeCharge = async () => {
        setOpenResumeCharge(true);
    }

    const confirmChargeResuming = async () => {
        setIsResumingCharge(true)
        try{
        const updatedTransaction = await TransactionsService.resumeCharge(existingTransaction.id)
        onClose(true)
        handleCloseCharge()
        } catch(e){
        console.error(e)
        }
        setIsResumingCharge(false)
    }

    const chargeActionNode = useMemo(() => {
        if(!existingTransaction){
            return <></>
        }
        if(TransactionWasBilled(existingTransaction)){
            if(existingTransaction.status == 'success'){
                return <Chip label="Charged Successfully" color="success" />
            }

            return 'Already charged'
        }
        if(existingTransaction.charge_cancelled_at == undefined){
        return <LoadingButton
            loading={isCancellingCharge}
            loadingPosition='start'
            startIcon={<CancelIcon />}
            variant='outlined'
            color='error'
            onClick={cancelCharge}
        >Cancel Charge</LoadingButton>
        }
        if(existingTransaction.charge_cancelled_at != undefined && TransactionWasBilled(existingTransaction) == false){
        return <LoadingButton
            loading={isResumingCharge}
            loadingPosition='start'
            startIcon={<ResumeIcon />}
            variant='outlined'
            color='success'
            onClick={resumeCharge}
        >Resume Charge</LoadingButton>
        }
    }, [existingTransaction])

    return (<>
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{existingTransaction ? `Edit Transaction ${existingTransaction.id}` : 'Create Transaction'}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1} columns={{ xs: 1, sm: 2 }} mb={1}>
                    <Grid item xs={1}>
                        <TextField
                            autoFocus
                            label="External Offer ID"
                            value={offerID}
                            onChange={(e) => {
                                setOfferID(e.target.value)
                            }}
                            fullWidth
                            type={'number'}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <DateTimePicker 
                            label="Charge Date"
                            inputFormat='M/d/yyyy h:mm a'
                            value={new Date(chargeDate).toLocalFromUTC()}
                            onChange={(date) => {
                                let utcDate = date.toUTCFromLocal()
                                let utcDateString = format(utcDate, 'yyyy-MM-dd HH:mm:ss')
                                setChargeDate(utcDateString)
                            }}
                            renderInput={(props) => <TextField {...props} />}
                        />
                    </Grid>
                </Grid>
                { existingTransaction && <Divider sx={{mt:1,mb:2}} /> }
                { existingTransaction && <Grid container spacing={1} columns={{ xs: 2, sm: 4 }} mb={1}>
                    <Grid item xs={1}>
                       <TextField
                            label="Flow ID"
                            value={existingTransaction.flow_id}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Credit Card ID"
                            value={existingTransaction.credit_card_id}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Tracking Info ID"
                            value={existingTransaction.tracking_info_id}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Internal Product ID"
                            value={existingTransaction.internal_campaign_id}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Decline Attempt ID"
                            value={existingTransaction.decline_attempt_id}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="External Campaign ID"
                            value={existingTransaction.external_campaign_id}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Offer ID"
                            value={existingTransaction.offer_id}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="External Order ID"
                            value={existingTransaction.external_order_id}
                            fullWidth
                            disabled
                        />
                    </Grid>
                </Grid> }
            </DialogContent>
            <Divider />
            <DialogActions sx={{ justifyContent: "space-between" }}>
                {existingTransaction && chargeActionNode}
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    onClick={handleOnSave}
                >{existingTransaction ? 'Save' : 'Create Transaction'}</LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog open={openCancelCharge} onClose={handleCloseCharge}>
        <DialogTitle>Cancel Charge</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText marginBottom={'1rem'}>
          Are you sure you want to cancel this charge?
          </DialogContentText>
          <TextField
            autoFocus
            label="Cancellation reason"
            value={cancellationNotes}
            onChange={(e) => {
              setCancellationNotes(e.currentTarget.value)
            }}
            fullWidth
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseCharge}>No</Button>
          <LoadingButton
            loading={isCancellingCharge}
            loadingPosition='start'
            startIcon={<CancelIcon />}
            color='error'
            onClick={confirmChargeCancellation}
          >Yes, cancel it</LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openResumeCharge} onClose={handleCloseCharge}>
        <DialogTitle>Resume Charge</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText marginBottom={'1rem'}>
          Are you sure you want to resume this charge?
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseCharge}>No</Button>
          <LoadingButton
            loading={isResumingCharge}
            loadingPosition='start'
            startIcon={<ResumeIcon />}
            color='success'
            onClick={confirmChargeResuming}
          >Yes, resume it</LoadingButton>
        </DialogActions>
      </Dialog>
        </>
    )
}

export default TransactionDialog;

