
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { InternalCampaign } from 'src/models/internalCampaign';
import { CampaignsService } from 'src/api/services/CampaignsService';

interface CampaignDialogProps {
    isOpen:boolean
    existingCampaign?:InternalCampaign
    onClose:(shouldRefresh:boolean) => void
}

const CampaignActivationDialog: FC<CampaignDialogProps> = ({isOpen = false, existingCampaign, onClose}) => {
    const [isLoading, setIsLoading] = useState(false)
  
    const handleClose = () => {
        onClose(false)
    }; 

    const handleOnSave = async () => {
        setIsLoading(true)
        if(existingCampaign?.active){
            await CampaignsService.deactivate(existingCampaign.id)
        } else {
            await CampaignsService.activate(existingCampaign.id)
        }
        setIsLoading(false)
        onClose(true)
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{ existingCampaign?.active ? 'Are you sure you want to de-activate this campaign?' : 'Are you sure you want to re-activate this campaign?' }</DialogTitle>
            <Divider />
            <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <LoadingButton
                color={ existingCampaign?.active ? 'error' : 'success'}
                loading={isLoading}
                onClick={handleOnSave}
            >{existingCampaign?.active? 'Yes, de-activate it' : 'Yes, re-activate it'}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default CampaignActivationDialog;

