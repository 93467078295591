import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  CardHeader,
  Link as MuiLink,
  Backdrop,
  styled,
  CircularProgress,
  Button,
  IconButton,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { PaginatedState } from 'src/models/paginatedState';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckedIcon from '@mui/icons-material/CheckCircleOutline';
import UncheckedIcon from '@mui/icons-material/ErrorOutline';
import { Offer } from 'src/models/offer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Collapse from '@mui/material/Collapse';
import { PaginatedResponse } from 'src/models/paginatedResponse';

interface PixelsTableProps {
  className?: string
  paginatedResponse?: PaginatedResponse<Offer>
  paginatedState: PaginatedState
  isRefreshing:boolean
  onPageChange:(page:number) => void
  onRowsPerPageChange:(rowsPerPage:number) => void
  onEditPixel:(offer:Offer) => void
}

const LimitedBackdrop = styled(Backdrop)(
  () => `
    position: absolute;
    z-index: 1;
`
);

const PixelsTable: FC<PixelsTableProps> = ({
  paginatedResponse, 
  paginatedState,
  isRefreshing = false, 
  onPageChange, 
  onRowsPerPageChange,
  onEditPixel,
}) => {
  const navigate = useNavigate()

  const handlePageChange = async (event: any, newPage: number) => {
    onPageChange(newPage)
  };

  const handleLimitChange = async (event: ChangeEvent<HTMLInputElement>) => {
    onRowsPerPageChange(parseInt(event.target.value))
  };

  return (
    <Card sx={{
      position:'relative'
    }}>
      <LimitedBackdrop open={isRefreshing}><CircularProgress size={'5rem'} /></LimitedBackdrop>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Offer</TableCell>
              <TableCell>EPC's</TableCell>
              <TableCell>Scrub</TableCell>
              <TableCell>Clicks</TableCell>
              <TableCell>Conversions</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedResponse?.data.map((item) => {
              return <PixelRow key={item.id} item={item} onEditPixel={onEditPixel} />
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={paginatedResponse?.total ?? 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={paginatedState.page}
          rowsPerPage={paginatedState.per_page}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

function PixelRow(props:any) {
  const { item, onEditPixel } = props
  const clicks = item.clicks_count
  const paidOrders = item.conversions_count
  const scrubbedOrders = item.scrubbed_count
  const marketingCost = (paidOrders - scrubbedOrders) * Number(item.cpa ?? 0)
  const epc = marketingCost / clicks

  const totalOrdersToMonitor = Number(item.scrub_monitor_total ?? 0)
  const numOrdersToScrub = Number(item.scrub_count ?? 0)

  const [open, setOpen] = useState(false);

  return (
    <>
    <TableRow
      key={item.id}
      sx={{ 
        "& td": {
          borderBottom: 'none'
        }
      }}
      >
      <TableCell>
        { item.scrub_affids && item.scrub_affids.length > 0 && 
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton> }
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {item.id}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {item.domain?.host ?? 'NO DOMAIN SELECTED'}/{item.path}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          color="text.primary"
          gutterBottom
          noWrap
        >
          ${epc.toFixed(2)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          color="text.primary"
          gutterBottom
          noWrap
        >
          { (numOrdersToScrub > 0 && totalOrdersToMonitor > 0) ? `${numOrdersToScrub}/${totalOrdersToMonitor}` : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {item.clicks_count ?? '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {item.conversions_count ?? '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display='flex'>
        <LoadingButton
          onClick={() => {
            onEditPixel(item)
          }}
        >Edit</LoadingButton>
        </Box>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell sx={{ padding: 0 }} colSpan={8}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="right">AFFID</TableCell>
                  <TableCell>Scrub</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item.scrub_affids && item.scrub_affids.map((aff) => (
                  <TableRow key={aff.affid}>
                    <TableCell component="th" scope="row" align="right">
                      {aff.affid}
                    </TableCell>
                    <TableCell>
                    { (aff.scrub_count > 0 && totalOrdersToMonitor > 0) ? `${aff.scrub_count}/${totalOrdersToMonitor}` : '-'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
    </>
  );
}

export default PixelsTable;
