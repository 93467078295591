import { Template } from "src/models/template";
import Api from "..";
import { PaginatedState } from "src/models/paginatedState";
import { PaginatedResponse } from "src/models/paginatedResponse";

export class TemplatesService {
  static async getAll(category:string = 'offer'):Promise<Template[]> {
    try {
      const { data } = await Api.client.get<Template[]>(`templates?category=${category}`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async search({
    name = '',
    category = 'offer',
  }, paginatedState: PaginatedState = { per_page: 10, page: 0 }):Promise<PaginatedResponse<Template>> {
    var params = {
      name,
      category,
    }

    const queryString = Object.entries(paginatedState)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

    const baseUrl = `templates/search`;
    const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    try {
      const { data } = await Api.client.post<PaginatedResponse<Template>>(urlWithQuery, params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async create(template:Partial<Template>, imageFile:any):Promise<Template> {
    try {
      const { data } = await Api.client.post('templates', {
        name:template.name, 
        image_file:imageFile,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async edit(template:Partial<Template>, imageFile:any):Promise<Template> {
    try {
      const { data } = await Api.client.post('templates/edit', {
        id:template.id, 
        name:template.name, 
        image_file:imageFile,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async delete(template:Partial<Template>):Promise<void> {
    try {
      await Api.client.delete(`templates/${template.id}`);
    } catch (e) {
      throw e;
    }
  }

  static async deactivate(templateID:number):Promise<Template> {
    try {
      const { data } = await Api.client.post(`templates/${templateID}/deactivate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async activate(templateID:number):Promise<Template> {
    try {
      const { data } = await Api.client.post(`templates/${templateID}/activate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

}