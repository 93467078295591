import { ApiException } from "src/models/apiError";
import { User } from "src/models/user";
import Api from "..";

export class AuthService {
    static async login({email, password}):Promise<User> {
        try {
          const { data } = await Api.client.post<User>('login', {
            'email': email,
            'password': password,
          });
          
          return data;
        } catch (e) {
          throw e;
        }
      }
}