import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';
import { Card } from '@mui/material';
import UsersTable from './UsersTable';

function ApplicationsUser() {
  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
          <Card>
            <UsersTable />
          </Card>
          </Grid>
          </Grid>
      </Container>
    </>
  );
}

export default ApplicationsUser;
