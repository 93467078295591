import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  CardHeader,
  Link as MuiLink,
  Backdrop,
  styled,
  CircularProgress,
  Button,
  IconButton,
  Chip,
} from '@mui/material';

import { Order } from 'src/models/order';
import { useNavigate } from 'react-router-dom';
import { OrdersService } from 'src/api/services/OrdersService';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { format, parse } from 'date-fns';
import RefreshIcon from '@mui/icons-material/Refresh';
import { PaginatedState } from 'src/models/paginatedState';
import { RebillOrder } from 'src/models/rebillOrder';
import { Transaction } from 'src/models/transaction';
import TransactionDialog from './TransactionDialog';

interface TransactionsTableProps {
  paginatedResponse?: PaginatedResponse<Transaction>
  isRefreshing:boolean
  refreshData: Function
  paginatedState: PaginatedState
  onPageChange:(page:number) => void
  onRowsPerPageChange:(rowsPerPage:number) => void
}

const LimitedBackdrop = styled(Backdrop)(
  () => `
    position: absolute;
    z-index: 1;
`
);

const TransactionsTable: FC<TransactionsTableProps> = ({paginatedResponse, isRefreshing = false, refreshData, paginatedState, onPageChange, onRowsPerPageChange}) => {
  const navigate = useNavigate()
  const [isTransactionDialogOpen, setIsTransactionDialogOpen] = useState(false)
  const [existingTransaction, setExistingTransaction] = useState<Transaction>()

  const onEditTransaction = (transaction:Transaction) => {
    setExistingTransaction(transaction)
    setIsTransactionDialogOpen(true)
  }

  const onTransactionDialogClose = (shouldRefresh:boolean) => {
    setIsTransactionDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  const handlePageChange = async (event: any, newPage: number) => {
    onPageChange(newPage)
  };

  const handleLimitChange = async (event: ChangeEvent<HTMLInputElement>) => {
    onRowsPerPageChange(parseInt(event.target.value))
  };

  return (
    <Card sx={{
      position:'relative'
    }}>
      <LimitedBackdrop open={isRefreshing}><CircularProgress size={'5rem'} /></LimitedBackdrop>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Offer ID</TableCell>
              <TableCell>Cycle</TableCell>
              <TableCell>Attempt</TableCell>
              <TableCell>Charge Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedResponse?.data.map((transaction) => {
              return (
                <TableRow
                  hover
                  key={transaction.id}
                  onClick={() => {
                    onEditTransaction(transaction)
                  }}
                  sx={{cursor:'pointer'}}
                  >
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {transaction.id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {format(new Date(transaction.created_at), 'M/d/yyyy h:mm a')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {transaction.external_offer_id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {transaction.cycle}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {transaction.attempt}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                      title={format(new Date(transaction.charge_at).toLocalFromUTC(), 'M/d/yyyy h:mm a')}
                    >
                      {format(new Date(transaction.charge_at).toLocalFromUTC(), 'M/d/yyyy')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {transaction.status == 'success' && <Chip label="Success" color="success" />}
                      {transaction.status == 'cancelled' && <Chip label="Cancelled" color="warning" />}
                      {transaction.status == 'declined' && <Chip label="Declined" color="error" />}
                      {transaction.status == 'pending' && 'Pending'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {transaction.cancellation_notes}
                      {transaction.status == 'pending' && transaction.decline_attempt_id != null && transaction.decline_attempt_id != undefined && `Salvage for order ${transaction.parent_id}`}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={paginatedResponse?.total ?? 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={paginatedState.page}
          rowsPerPage={paginatedState.per_page}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
      <TransactionDialog isOpen={isTransactionDialogOpen} onClose={onTransactionDialogClose} existingTransaction={existingTransaction} />
    </Card>
  );
};

export default TransactionsTable;
