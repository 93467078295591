import { FC, ReactNode } from 'react';
import { Box, useTheme } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import { useAuth } from 'src/providers/AuthProvider';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`
            }
          }}
        >
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
