
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Alert, Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { PixelsService } from 'src/api/services/PixelsService';
import { DeclineAttempt } from 'src/models/declineAttempt';
import NewDeclineAttemptIcon from '@mui/icons-material/AddCircle';
import { Offer } from 'src/models/offer';
import { ApiException } from 'src/models/apiError';
import AddIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Delete';

interface PixelDialogProps {
    isOpen: boolean
    existingPixel?: Offer
    onClose: (shouldRefresh: boolean) => void
}

const PixelDialog: FC<PixelDialogProps> = ({ isOpen = false, existingPixel, onClose }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>('')

    const [cpa, setCpa] = useState<string|number>('')
    const [scrubCount, setScrubCount] = useState<string|number>('')
    const [scrubMonitorTotal, setScrubMonitorTotal] = useState<string|number>('')
    const [scrubAffids, setScrubAffids] = useState<any>()

    const handleClose = () => {
        onClose(false)
    };

    useEffect(() => {
        if (existingPixel) {
            setCpa(existingPixel.cpa)
            setScrubCount(existingPixel.scrub_count)
            setScrubMonitorTotal(existingPixel.scrub_monitor_total)
            setScrubAffids(existingPixel.scrub_affids)
        } else {
            setCpa('')
            setScrubCount('')
            setScrubMonitorTotal('')
            setScrubAffids(undefined)
        }
        setError('')
    }, [existingPixel])

    const handleOnSave = async () => {
        setIsLoading(true)
        setError('')
        try{
            if (existingPixel) {
                //Edit
                await PixelsService.edit({
                    id: existingPixel.id,
                    cpa,
                    scrub_count: scrubCount,
                    scrub_monitor_total: scrubMonitorTotal,
                    scrub_affids: scrubAffids,
                })
            }
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{existingPixel ? 'Edit Pixel' : 'Create Pixel'}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1} columns={{ xs: 1 }} mb={1}>
                    <Grid item xs={1}>
                        <TextField
                            autoFocus
                            label="CPA"
                            placeholder='30'
                            value={cpa}
                            onChange={(e) => {
                                setCpa(e.target.value)
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">$</InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Divider sx={{mb: 1}} />
                <Typography mb={2} mt={2}>
                    Scrub Settings
                </Typography>
                <Grid container spacing={1} columns={{ xs: 2 }} mb={1}>
                    <Grid item xs={1}>
                        <TextField
                            label="Total Orders to monitor"
                            placeholder='0'
                            value={scrubMonitorTotal}
                            onChange={(e) => {
                                setScrubMonitorTotal(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Scrub Count"
                            placeholder='0'
                            value={scrubCount}
                            onChange={(e) => {
                                setScrubCount(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Divider sx={{mb: 1}} />
                <Typography mb={2} mt={2}>
                    AFFIDs Settings
                </Typography>
                <Grid item xs={2}>
                    { scrubAffids && scrubAffids.map((aff, i) => {
                        return <Grid container spacing={1} columns={{ xs: 7 }} key={`aff_${i}`} mb={2}>
                    <Grid item xs={3}>
                    <TextField
                    label={`AFFID`}
                    value={aff.affid}
                    onChange={(e) => {
                        let newContent = [...scrubAffids]
                        newContent[i].affid = e.target.value
                        setScrubAffids(newContent)
                    }}
                    fullWidth
                /></Grid> 
                    <Grid item xs={3}>
                    <TextField
                    label={`Scrub Count`}
                    value={aff.scrub_count}
                    onChange={(e) => {
                        let newContent = [...scrubAffids]
                        newContent[i].scrub_count = e.target.value
                        setScrubAffids(newContent)
                    }}
                    fullWidth
                /></Grid> 
                <Grid item xs={1} display="flex" direction="column" alignItems="center" justifyContent="center">
                    <IconButton
                        color='error'
                        size='small'
                        onClick={() => {                    
                            let newContent = [...scrubAffids]
                            newContent.splice(i, 1)
                            setScrubAffids(newContent)
                        }}
                    >
                        <CancelIcon />
                    </IconButton>
                </Grid>
                </Grid>
                }) }
                
                <Button component="label" endIcon={<AddIcon />} onClick={() => {
                    let newContent = scrubAffids ? [...scrubAffids] : []
                    newContent.push({
                        affid:'',
                        scrub_count:'',
                    })
                    setScrubAffids(newContent)
                }}>
                    Add AFFID setting
                </Button>
                </Grid>
            </DialogContent>
            <Divider />
            { error && <>
            <Alert severity="error">
               {error}
            </Alert>
            <Divider />
            </> }
            <DialogActions>
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    onClick={handleOnSave}
                >{existingPixel ? 'Save' : 'Create Pixel'}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default PixelDialog;

