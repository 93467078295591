import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';
import PageHeader from './PageHeader';
import { useEffect, useState } from 'react';
import { PaginatedState } from 'src/models/paginatedState';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { DomainsService } from 'src/api/services/DomainsService';
import DomainsTable from './DomainsTable';
import { Domain } from 'src/models/domain';
import DomainDialog from './DomainDialog';
import { DeclineCode } from 'src/models/declineCode';
import NewDomainIcon from '@mui/icons-material/AddCircle';
import { LoadingButton } from '@mui/lab';
import DomainActivationDialog from './DomainActivationDialog';

function ApplicationsDomains() {
  const [paginatedResponse, setPaginatedResponse] = useState<PaginatedResponse<Domain>>()
  const [paginatedState, setPaginatedState] = useState<PaginatedState>({per_page: 10, page: 0})
  const [searchTerms, setSearchTerms] = useState<Partial<Domain>>({
    host: '',
  })

  const [isSearching, setIsSearching] = useState(false)
  const [serverIP, setServerIP] = useState<string>()

  const onSearch = async (terms:Partial<Domain>) => {
    console.log(terms)
    const newTerms = {...terms}
    setSearchTerms(newTerms)
  }

  const onClear = async (terms:Partial<Domain>) => {
    setSearchTerms(terms)
  }

  const refreshData = async () => {
    setIsSearching(true)
    try{
      const response = await DomainsService.search({
        ...searchTerms,
      }, paginatedState)
      setPaginatedResponse(response)
      if(!serverIP){
        const ip = await DomainsService.ip()
        setServerIP(ip)
      }
    } catch(e){}
    setIsSearching(false)
  }

  useEffect(() => {
    refreshData()
  },[searchTerms, paginatedState])

  const onPageChange = (newPage: number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.page = newPage
    setPaginatedState(newPaginatedState)
  };

  const onRowsPerPageChange = (newRowsPerPage:number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.per_page = newRowsPerPage
    setPaginatedState(newPaginatedState)
  };

  const [isDomainDialogOpen, setIsDomainDialogOpen] = useState(false)
  const [existingDomain, setExistingDomain] = useState<Domain>()
  const [isRefreshingDomain, setIsRefreshingDomain] = useState(false)

  const onNewDomain = () => {
    setExistingDomain(undefined)
    setIsDomainDialogOpen(true)
  }

  const onEditDomain = (domain:Domain) => {
    setExistingDomain(domain)
    setIsDomainDialogOpen(true)
  }

  const onDomainDialogClose = (shouldRefresh:boolean) => {
    setIsDomainDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  const onRefreshDomain = async (domain:Domain) => {
    setIsRefreshingDomain(true)
    const refreshedDomain = await DomainsService.refresh(domain)
    if(refreshedDomain && refreshedDomain.checked != domain.checked){
      await refreshData()
    }
    setIsRefreshingDomain(false)
  }

  const [isDomainActivationDialogOpen, setIsDomainActivationDialogOpen] = useState(false)

  const onActivateDomain = (domain:Domain) => {
    setExistingDomain(domain)
    setIsDomainActivationDialogOpen(true)
  }

  const onDeactivateDomain = (domain:Domain) => {
    setExistingDomain(domain)
    setIsDomainActivationDialogOpen(true)
  }

  const onDomainActivationDialogClose = (shouldRefresh:boolean) => {
    setIsDomainActivationDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  return (
    <>
      <Helmet>
        <title>Domains</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
            {/*<PageHeader 
              searchTerms={searchTerms} 
              isSearching={isSearching} 
              onSearch={onSearch} 
              onClear={onClear} 
              onNewDomain={onNewDomain} 
            />*/}
            <LoadingButton
              loading={isSearching}
              loadingPosition='start'
              startIcon={<NewDomainIcon />}
              variant='contained'
              onClick={() => {
                onNewDomain()
              }}
            >New Domain</LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <DomainsTable 
              paginatedResponse={paginatedResponse} 
              paginatedState={paginatedState} 
              isRefreshing={isSearching} 
              onPageChange={onPageChange} 
              onRowsPerPageChange={onRowsPerPageChange}
              onEditDomain={onEditDomain}
              onActivateDomain={onActivateDomain}
              onDeactivateDomain={onDeactivateDomain}
              onRefreshDomain={onRefreshDomain}
              isRefreshingDomain={isRefreshingDomain} 
            />
          </Grid>
        </Grid>
      </Container>
      <DomainActivationDialog 
        isOpen={isDomainActivationDialogOpen} 
        onClose={onDomainActivationDialogClose} 
        existingDomain={existingDomain} 
      />
      <DomainDialog 
        isOpen={isDomainDialogOpen} 
        onClose={onDomainDialogClose} 
        existingDomain={existingDomain} 
        serverIP={serverIP}
      />
    </>
  );
}

export default ApplicationsDomains;
